import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../shared/services/auth.service";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
// import {MatTabsModule} from '@angular/material/tabs';

@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.css']
})
export class AccountingComponent implements OnInit {
  admin = false;
  auth = false;
  accountant = false;
  checkForChapter;

  constructor(public authService: AuthService, private route: Router) {
    this.route.events.subscribe(event => {
      if(event instanceof NavigationStart){}
      if(event instanceof NavigationEnd){
        if (this.authService.getCurrentUser() !== null) {
          // console.log(this.route.url);
          this.auth = this.authService.isLoggedIn;
          this.accountant = this.authService.getCurrentUser().uid === 'kb8TZYNLqGQLQuGySOHR5Cahvsw2';
          this.checkForChapter = this.route.url === '/accounting/month-spending' ? 'month-spending' : this.route.url === '/accounting/year-spending' ? 'year-spending' : this.route.url === '/accounting/fuel-spending' ? 'fuel-spending': this.route.url === '/accounting/salaries' ? 'salaries': this.route.url === '/accounting/additional-spending' ? 'additional-spending': this.route.url === '/accounting/salaries/driver-salary' ? 'salaries': this.route.url === '/accounting/salaries/logist-salary' ? 'salaries': this.route.url === '/accounting/salaries/accountant-salary' ? 'salaries': this.route.url === '/accounting/taxes' ? 'taxes': '/accounting/schedule' ? 'schedule' : '';
          // console.log(this.checkForChapter);
        } else {
          this.admin = false;
          this.auth = false;
          this.checkForChapter = '';
        }
      }
    });
  }

  ngOnInit() {
    this.authService.checkAdmin().subscribe(admin => {
      this.admin = admin;
    });
  }

}
