import { Component, OnInit } from '@angular/core';
import {AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from "@angular/fire/storage";
import {AuthService} from "../../../shared/services/auth.service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-year-spending',
  templateUrl: './year-spending.component.html',
  styleUrls: ['./year-spending.component.css']
})
export class YearSpendingComponent implements OnInit {

  public scrollTop: number;
  // public newYearSpending: any = {};
  public newAccount: any = {};
  public accounts: any;
  public filter: string;
  public accountFilter: any;
  public newDocument: any = {};
  public userId: any;
  public currentYear = new Date().getFullYear();;
  public yearFilter: any = this.currentYear;
  public currentYears: any = [];

  filterSwitcher = false;
  showDocuments: boolean = false;
  showModal: boolean = false;
  showConfirm: boolean = false;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;

  constructor(private afStorage: AngularFireStorage, public authService: AuthService) { }

  ngOnInit() {
    this.userId = this.authService.getCurrentUser().uid;
    // this.currentYear = new Date().getFullYear();

    this.getYears();

    this.getYearAccounts();
  }

  getYears () {
    for (let i = 0; i <= 10; i++) {
      this.currentYears.push(this.currentYear);
      --this.currentYear;
    }
  }

  //get year accounts
  getYearAccounts(){
    this.authService.getYearAccounts().get().then((item: any) => {
      this.accounts = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
      this.accountFilter = [...this.accounts];
    });
  }

  //add new account
  addYearAccounts(){
    // let checkForYear = false;
    if ( !!this.newAccount.year &&
      !!this.newAccount.name &&
      !!this.newAccount.summ ) {
      // for (let i = 0; i < this.accounts.length; i++) {
      //   if (this.accounts[i].year === this.newAccount.year) {
      //     checkForYear = false;
      //     break;
      //   } else {
      //     checkForYear = true;
      //   }
      // }
      // if (checkForYear) {
        this.authService.addYearAccounts(this.newAccount).then(res => {
          this.getYearAccounts();
          this.newAccount = {};
          this.showModal = false;
        });
      // }
    }
  }

  //edit account
  editYearAccount(account) {
    this.getYearAccounts();
    this.newAccount = Object.assign({}, account);
    this.showModal = true;
  }

  //update account
  updateYearAccount(account) {
    this.authService.updateYearAccounts(account).then(res => {
      this.getYearAccounts();
      this.newAccount = {};
      this.newDocument = {};
      this.showModal = false;
    })
  }

  //remove account
  removeYearAccount(account) {
    this.authService.removeYearAccounts(account).then(res => {
      this.newAccount = {};
      this.getYearAccounts();
      this.showConfirm = false;
    })
  }

  //remove document
  removeDocument(account, ref){
    account.documents = account.documents.filter(el => el.ref != ref);
    this.updateDocument(account);
  }

  //update document
  updateDocument(account) {
    this.authService.updateYearAccounts(account).then(res => {
      this.getYearAccounts();
      this.newDocument = {};
    })
  }

  //upload document
  upload(event, account) {
    const id = Math.random().toString(36).substring(2);
    this.ref = this.afStorage.ref(id);
    this.task = this.ref.put(event.target.files[0]);
    this.uploadProgress = this.task.percentageChanges();
    this.task.then(res => {
      this.ref.getDownloadURL().subscribe(res => {
        this.newDocument.ref = res;
      });
    });
  }

  //add document
  addDocument(account){
    if(!account.documents) account.documents = [];
    if(this.userId === 'kb8TZYNLqGQLQuGySOHR5Cahvsw2'){
      account.status = 'sent'
    }
    if (this.newDocument.name && this.newDocument.ref) {
      account.documents.push(this.newDocument);
      this.updateDocument(account);
    }
  }

  //scroll table
  track(event){
    this.scrollTop  = event;
  }

  //search method
  changeFilter(search) {
    // let toDate = new Date(this.toDate).getTime();
    // let fromDate = new Date(this.fromDate).getTime();
    this.accounts = this.accountFilter.filter(item => {
      // let fromDateItem = new Date(item.fromDate).getTime();
      // let searchFiled = item.NIP + item.price + item.distToFreight + item.distWithFreight + item.fromDate + item.toDate + item.fromPlace + item.toPlace;
      // if(toDate && fromDate) {
      //   if (fromDateItem < fromDate || fromDateItem > toDate) {
      //     return false;
      //   }
      // }
      if (this.yearFilter != 'all') {
        // debugger
        if (item.year != this.yearFilter) {
          return false;
        }
      }

      if (search) {
        let searchFiled = item.year + item.name + item.summ;
        if (searchFiled.toLowerCase().indexOf(search.toLowerCase()) == -1){
          return false;
        }
      }
      return true;

    });
    // this.countSallary();
  }

  showFilters() {
    if (this.filterSwitcher) {
      this.filterSwitcher = false;
    } else {
      this.filterSwitcher = true;
    }
  }

}
