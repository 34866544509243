import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../shared/services/auth.service";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";

@Component({
  selector: 'app-salaries',
  templateUrl: './salaries.component.html',
  styleUrls: ['./salaries.component.css']
})
export class SalariesComponent implements OnInit {

  auth = false;
  accountant = false;
  checkForChapter;

  constructor(public authService: AuthService, private route: Router) {
    this.route.events.subscribe(event => {
      if(event instanceof NavigationStart){}
      if(event instanceof NavigationEnd){
        if (this.authService.getCurrentUser() !== null) {
          // console.log(this.route.url);
          this.auth = this.authService.isLoggedIn;
          this.accountant = this.authService.getCurrentUser().uid === 'kb8TZYNLqGQLQuGySOHR5Cahvsw2';
          this.checkForChapter = this.route.url === '/accounting/salaries/driver-salary' ? 'driver-salary' : this.route.url === '/accounting/salaries/logist-salary' ? 'logist-salary' : this.route.url === '/accounting/salaries/accountant-salary' ? 'accountant-salary': '';
          // console.log(this.checkForChapter);
        } else {
          this.auth = false;
          this.checkForChapter = '';
        }
      }
    });
  }

  ngOnInit() {
  }


}
