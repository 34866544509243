import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../shared/services/auth.service';
// import {Data} from "@angular/router";
import {AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from "@angular/fire/storage";
import {Observable} from "rxjs/index";
import * as moment from 'moment';

@Component({
  selector: 'app-admin-orders',
  templateUrl: './admin-orders.component.html',
  styleUrls: ['./admin-orders.component.css']
})
export class AdminOrdersComponent implements OnInit {

  public cars: any = [];
  filterSwitcher = false;
  public drivers: any = [];
  public logists: any = [];
  public statuses: any = [
    'wait',
    'inRoad',
    'finish',
  ];
  public newOrder: any = {};
  public money: any = {};
  public orders: any = [];
  public fakeOrders: any = [];
  public ordersFilter: any;
  public userId: any;
  public filter: string;
  public x: any = new Date;
  public fromDate: any = this.formatDate(new Date(this.x.getFullYear(), this.x.getMonth(), 1));
  public toDate: any = this.formatDate(new Date(this.x.getFullYear(), this.x.getMonth() + 1, 0));
  public carFilter: any = 'all';
  public driverFilter: any = 'all';
  public logistFilter: any = 'all';
  public statusFilter: any = 'all';
  public newDocument: any = {};
  public currentDatee: any;
  public scrollTop: number;

  showModal: boolean = false;
  showZp: boolean = false;
  showConfirm: boolean = false;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;

  constructor(private afStorage: AngularFireStorage, public authService: AuthService) { }

  ngOnInit() {
    this.userId = this.authService.getCurrentUser().uid;
    this.currentDatee = moment().format('MM-DD-YYYY');

    this.authService.getCars().get().then((item: any) => {
      this.cars = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
    });

    this.authService.getDrivers().get().then((item: any) => {
      this.drivers = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
    });

    this.authService.getLogists().get().then((item: any) => {
      this.logists = item.docs.map(doc => {
        doc = doc.data();
        return doc;
      });
    });
    this.getOrders();
  }

  upload(event, order) {
    const id = Math.random().toString(36).substring(2);
    this.ref = this.afStorage.ref(id);
    this.task = this.ref.put(event.target.files[0]);
    this.uploadProgress = this.task.percentageChanges();
    this.task.then(res => {
      this.ref.getDownloadURL().subscribe(res => {
        order['document'] = res;
        this.updateOrder(order);
      });
    });
  }

  track(event){
    this.scrollTop  = event;
  }

  formatDate (date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  getOrders(){
    this.authService.getOrders().get().then((item: any) => {
      // debugger
      this.orders = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
      // this.orders.forEach(el => {
      //   if(el.approve && !el.orderPaid){
      //     debugger
      //     el.orderPaid = true;
      //     this.updateOrder(el);
      //   }
      // })
      this.orders.sort((a, b) => {
        return <any>new Date(b.fromDate) - <any>new Date(a.fromDate);
      });

      this.fakeOrders = this.orders;
      this.ordersFilter = [...this.orders];
      this.changeFilter();
      this.countSallary();
    });
  }

  payOrder(order){
    order.orderPaid = true;
    this.updateOrder(order);
  }

  unPayOrder(order){
    order.orderPaid = false;
    this.updateOrder(order);
  }


  getDriverById(id){
    return this.drivers.find(el => el.id == id).name;
  }
  getCarById(id){
    return this.cars.find(el => el.id == id).model;
  }
  getLogistById(id){
    return this.logists.find(el => el.id == id).name;
  }
  getDate(date){
    return new Date(date);
  }

  addOrder(){
    if ( !!this.newOrder.NIP &&
      !!this.newOrder.car &&
      !!this.newOrder.distToFreight &&
      !!this.newOrder.distWithFreight &&
      !!this.newOrder.driver &&
      !!this.newOrder.fromDate &&
      !!this.newOrder.fromPlace &&
      !!this.newOrder.price &&
      !!this.newOrder.toDate &&
      !!this.newOrder.inVoice &&
      !!this.newOrder.toPlace) {
      this.newOrder['createdBy'] = this.userId;
      // this.authService.addOrder(this.newOrder).then(res => {
      //   this.getOrders();
      //   this.newOrder = {};
      //   this.showModal = false;
      // });
    }
  }

  updateOrder(order) {
    this.authService.updateOrder(order).then(res => {
      this.getOrders();
      this.newOrder = {};
      this.showModal = false;
    });
  }

  removeOrder(order) {
    this.authService.removeOrder(order).then(res => {
      this.newOrder = {};
      this.getOrders();
      this.showConfirm = false;

    })
  }

  editOrder(order) {
    this.newOrder = Object.assign({}, order);
    this.showModal = true;
  }

  changeFilter(search?) {
    let toDate = new Date(this.toDate).getTime();
    let fromDate = new Date(this.fromDate).getTime();
    this.orders = this.ordersFilter.filter(item => {
      // let fromDateItem = new Date(item.fromDate).getTime();
      let fromDateItem = new Date(this.formatDate(new Date(item.fromDate.substr(0,4), item.fromDate.substr(5,2) - 1, item.fromDate.substr(8,2)))).getTime();
      let toDateItem = new Date(this.formatDate(new Date(item.toDate.substr(0,4), item.toDate.substr(5,2) - 1, item.toDate.substr(8,2)))).getTime();
      // let searchFiled = item.NIP + item.price + item.distToFreight + item.distWithFreight + item.fromDate + item.toDate + item.fromPlace + item.toPlace;
      if(toDate && fromDate) {
        if ((fromDateItem < fromDate || fromDateItem > toDate) && (toDateItem < fromDate || toDateItem > toDate)) {
          return false;
        }
      }
      if (this.carFilter != 'all') {
        if (item.car !== this.carFilter) {
          return false;
        }
      }
      if (this.driverFilter != 'all') {
        if (item.driver  !== this.driverFilter) {
          return false;
        }
      }
      if (this.logistFilter != 'all') {
        if (item.createdBy  !== this.logistFilter) {
          return false;
        }
      }
      if (this.statusFilter != 'all') {
        if ((this.statusFilter == 'paid' && !item.orderPaid) || (this.statusFilter == 'unpaid' && item.orderPaid)) {
          return false;
        }
      }
      if (search) {
          let searchFiled = item.NIP + item.campaignName + item.car  + item.createdBy  + item.factureNumber  + item.driver + item.price + item.distToFreight + item.distWithFreight + item.type + item.fromDate + item.toDate + item.fromPlace + item.toPlace + item.inVoice;
          if (searchFiled.toLowerCase().indexOf(search.toLowerCase()) == -1){
            return false;
          }
      }
      return true;

    });
    this.countSallary();
  }

  countSallary(){
    this.money.cars = {};
    this.money.fullSalary = 0;
    this.money.allSalary = 0;
    this.money.allDistance = 0;
    this.orders.forEach(order=>{
      this.money.allSalary += order.price;
      this.money.allDistance += (order.distWithFreight + order.distToFreight)
    });

    let cars = [];

    this.money.cars = cars;
  }

  addDocument(order){
    if(!order.documents) order.documents = [];
    order.documents.push(this.newDocument);
    this.updateDocument(order);
  }

  removeDocument(order, ref){
    order.documents = order.documents.filter(el => el.ref != ref);
    this.updateDocument(order);
  }


  updateDocument(order) {
    this.authService.updateOrder(order).then(res => {
      this.getOrders();
      this.newDocument = {};
    })
  }

  public records: any[] = [];
  @ViewChild('csvReader') csvReader: any;

  uploadListener($event: any): void {

    let text = [];
    let files = $event.srcElement.files;

    if (this.isValidCSVFile(files[0])) {

      let input = $event.target;
      let reader = new FileReader();
      reader.readAsText(input.files[0]);

      reader.onload = () => {
        let csvData = reader.result;
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);

        let headersRow = this.getHeaderArray(csvRecordsArray);

        let arr = [];

        this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);

        for(let i = 0; i < this.fakeOrders.length; i++){
            for(let j = 0; j < this.records.length; j++){
              if(!this.fakeOrders[i].approve && this.checkerNumberFacture(this.records[j], this.fakeOrders[i].factureNumber)){
                this.fakeOrders[i].approve = true;
                this.fakeOrders[i].factureInfo = this.records[j];
                this.fakeOrders[i].orderPaid = true;
                arr.push(this.fakeOrders[i]);
                this.updateOrder(this.fakeOrders[i]);
              }
            }
        }

        alert('Нових оплат: ' + arr.length);
        console.log(arr);

        this.getOrders();
        this.changeFilter();
      };

      reader.onerror = function () {
        console.log('error is occured while reading file!');
      };

    } else {
      alert("Please import valid .csv file.");
      this.fileReset();
    }
  }

  checkerNumberFacture(record, facture){
    if(facture == undefined) return false;
    let symbols = ['/',',','','-',   '\\',   '.','~', ' ', ':',';'];
    let parts = facture.split('/');
    for(let i = 0; i < symbols.length; i++){
      let x = parts.join(symbols[i]);
      if((record.description.indexOf(x) != -1) || (record.NIP.indexOf(x) != -1)){
        return true;
      }
    }
    return false
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    let csvArr = [];
    for (let i = 1; i < csvRecordsArray.length - 1; i++) {
      let curruntRecord = csvRecordsArray[i].split(';');
      let csvRecord = {
        dataIncome: '',
        dataIncome2: '',
        NIP: '',
        description: '',
        currency: '',
        sum1: '',
        sum2: '',
        sum3: ''

      };
      if(!curruntRecord[0] || !curruntRecord[1]) return;
      csvRecord.dataIncome = curruntRecord[0].trim();
      csvRecord.dataIncome2 = curruntRecord[1].trim();
      csvRecord.NIP = curruntRecord[2].trim();
      csvRecord.description = curruntRecord[3].trim();
      csvRecord.currency = curruntRecord[4].trim();
      csvRecord.sum1 = curruntRecord[5].trim();
      csvRecord.sum2 = curruntRecord[6].trim();
      csvRecord.sum3 = curruntRecord[7].trim();
      csvArr.push(csvRecord);
    }
    return csvArr;
  }

  isValidCSVFile(file: any) {
    return file.name.endsWith(".csv");
  }

  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string>csvRecordsArr[0]).split(',');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  fileReset() {
    this.csvReader.nativeElement.value = "";
    this.records = [];
  }

  showFilters() {
    if (this.filterSwitcher) {
      this.filterSwitcher = false;
    } else {
      this.filterSwitcher = true;
    }
  }


}
