import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../shared/services/auth.service';
import {AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from "@angular/fire/storage";
import {Observable} from "rxjs";

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.css']
})
export class DriversComponent implements OnInit {
  filterSwitcher = false;
  public drivers: any;
  public newDriver: any = {};
  public userId: any;
  public newDocument: any = {};
  public logist: any;
  public scrollTop: number;

  showModal: boolean = false;
  showConfirm: boolean = false;
  showDocuments: boolean = false;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;

  constructor(private afStorage: AngularFireStorage, public authService: AuthService) { }

  ngOnInit() {
    this.userId = this.authService.getCurrentUser().uid;
    this.logist = this.authService.getCurrentUser();
    this.getDrivers();
  }

///////////driver methods
  //get all drivers
  getDrivers(){
    this.authService.getDrivers().get().then((item: any) => {
      this.drivers = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
    });
  }

  //add new driver
  addDriver(){
    if ( !!this.newDriver.name &&
      !!this.newDriver.phone &&
      !!this.newDriver.polandPhone) {
      this.authService.addDriver(this.newDriver).then(res => { //
        this.getDrivers(); //
        this.newDriver = {};
        this.showModal = false;
      });
    }
  }

  //edit driver
  editDriver(driver) {
    this.getDrivers();
    this.newDriver = Object.assign({}, driver);
    this.showModal = true;
  }

  //update driver
  updateDriver(driver) {
    this.authService.updateDriver(driver).then(res => {
      this.getDrivers();
      this.newDriver = {};
      this.newDocument = {};
      this.showModal = false;
    })
  }

  //remove driver
  removeDriver(driver) {
    this.authService.removeDriver(driver).then(res => {
      this.newDriver = {};
      this.getDrivers();
      this.showConfirm = false;
    })
  }


////////document methods
  //remove document
  removeDocument(driver, ref){
    driver.documents = driver.documents.filter(el => el.ref != ref);
    this.updateDocument(driver);
  }

  //update document
  updateDocument(driver) {
    this.authService.updateDriver(driver).then(res => {
      this.getDrivers();
      this.newDocument = {};
    })
  }

  //upload document
  upload(event, driver) {
    const id = Math.random().toString(36).substring(2);
    this.ref = this.afStorage.ref(id);
    this.task = this.ref.put(event.target.files[0]);
    this.uploadProgress = this.task.percentageChanges();
    this.task.then(res => {
      this.ref.getDownloadURL().subscribe(res => {
        this.newDocument.ref = res;
      });
    });
  }

  //add document
  addDocument(driver){
    if(!driver.documents) driver.documents = [];
    if(this.userId === 'kb8TZYNLqGQLQuGySOHR5Cahvsw2'){
      driver.status = 'sent'
    }
    if (this.newDocument.name && this.newDocument.ref) {
      driver.documents.push(this.newDocument);
      this.updateDocument(driver);
    }
  }

  //scroll table
  track(event){
    this.scrollTop  = event;
  }

  showFilters() {
    if (this.filterSwitcher) {
      this.filterSwitcher = false;
    } else {
      this.filterSwitcher = true;
    }
  }
}
