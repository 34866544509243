import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../shared/services/auth.service';
import {AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from "@angular/fire/storage";
import {Observable} from "rxjs/index";
import * as moment from 'moment';

@Component({
  selector: 'app-acauntant-orders',
  templateUrl: './acauntant-orders.component.html',
  styleUrls: ['./acauntant-orders.component.css']
})
export class AcountantOrdersComponent implements OnInit {
  public cars: any = [];
  public img: any = null;
  public drivers: any = [];
  public logists: any = [];
  public statusFilter: any = 'all';
  public statuses: any = [
    'wait',
    'inRoad',
    'finish',
  ];
  public newOrder: any = {};
  public money: any = {};
  public orders: any = [];
  public ordersFilter: any;
  public userId: any;
  public filter: string;
  public x: any = new Date;
  public fromDate: any = this.formatDate(new Date(this.x.getFullYear(), this.x.getMonth(), 1));
  public toDate: any = this.formatDate(new Date(this.x.getFullYear(), this.x.getMonth() + 1, 0));
  public carFilter: any = 'all';
  public driverFilter: any = 'all';
  public logistFilter: any = 'all';
  public newDocument: any = {};
  public scrollTop: number;

  filterSwitcher = false;
  showModal: boolean = false;
  // isFactureNumber: boolean = false;
  showZp: boolean = false;
  showConfirm: boolean = false;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;

  constructor(private afStorage: AngularFireStorage, public authService: AuthService) {
  }

  ngOnInit() {
    this.userId = this.authService.getCurrentUser().uid;

    this.authService.getCars().get().then((item: any) => {
      this.cars = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
    });

    this.authService.getDrivers().get().then((item: any) => {
      this.drivers = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
    });

    this.authService.getLogists().get().then((item: any) => {
      this.logists = item.docs.map(doc => {
        doc = doc.data();
        return doc;
      });
    });
    this.getOrders();
  }

  formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  upload(event, order) {
    const id = Math.random().toString(36).substring(2);
    this.ref = this.afStorage.ref(id);
    this.task = this.ref.put(event.target.files[0]);
    this.uploadProgress = this.task.percentageChanges();
    this.task.then(res => {
      this.ref.getDownloadURL().subscribe(res => {
        this.newDocument.ref = res;
      });
    });
  }

  getOrders() {
    this.authService.getOrders().get().then((item: any) => {
      this.orders = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
      this.orders.sort((a, b) => {
        return <any>new Date(b.fromDate) - <any>new Date(a.fromDate);
      });
      this.ordersFilter = [...this.orders];
      this.changeFilter();
    });

  }


  getDriverById(id) {
    return this.drivers.find(el => el.id == id).name;
  }

  getCarById(id) {
    return this.cars.find(el => el.id == id).model;
  }

  getLogistById(id) {
    return this.logists.find(el => el.id == id).name;
  }

  getDate(date) {
    return new Date(date);
  }

  changeFilter(search?) {
    let toDate = new Date(this.toDate).getTime();
    let fromDate = new Date(this.fromDate).getTime();

    this.orders = this.ordersFilter.filter(item => {
      // let fromDateItem = new Date(item.fromDate).getTime();
      let fromDateItem = new Date(this.formatDate(new Date(item.fromDate.substr(0, 4), item.fromDate.substr(5, 2) - 1, item.fromDate.substr(8, 2)))).getTime();
      let toDateItem = new Date(this.formatDate(new Date(item.toDate.substr(0, 4), item.toDate.substr(5, 2) - 1, item.toDate.substr(8, 2)))).getTime();
      // let searchFiled = item.NIP + item.price + item.distToFreight + item.distWithFreight + item.fromDate + item.toDate + item.fromPlace + item.toPlace;
      if (toDate && fromDate) {
        if ((fromDateItem < fromDate || fromDateItem > toDate) && (toDateItem < fromDate || toDateItem > toDate)) {
          return false;
        }
      }
      if (this.carFilter != 'all') {
        if (item.car !== this.carFilter) {
          return false;
        }
      }
      if (this.driverFilter != 'all') {
        if (item.driver !== this.driverFilter) {
          return false;
        }
      }
      if (this.logistFilter != 'all') {
        if (item.createdBy !== this.logistFilter) {
          return false;
        }
      }
      if (this.statusFilter != 'all') {
        if ((this.statusFilter == 'paid' && !item.orderPaid) || (this.statusFilter == 'unpaid' && item.orderPaid)) {
          return false;
        }
      }
      if (search) {
        let searchFiled = item.NIP + item.campaignName + item.car + item.createdBy + item.factureNumber + item.driver + item.price + item.distToFreight + item.distWithFreight + item.type + item.fromDate + item.toDate + item.fromPlace + item.toPlace + item.inVoice;
        if (searchFiled.toLowerCase().indexOf(search.toLowerCase()) == -1) {
          return false;
        }
      }
      return true;

    });
  }

  updateOrder(order) {
    this.authService.updateOrder(order).then(res => {
      this.getOrders();
      this.newOrder = {};
      this.showModal = false;
    })
  }

  addDocument(order) {
    // if (order.factureNumber) {
    if (!order.documents) order.documents = [];
    // this.newDocument.name = 'facture' + (order.documents.length + 1); ///
    this.newDocument.name = 'facture'; ///
    order.type = 'sent';
    order.daysToPay = order.daysToPay + 10;
    order.deadlineToPay = moment().add(order.daysToPay, 'days').format('MM-DD-YYYY'); //////
    this.img = null;
    order.documents.push(this.newDocument);
    this.updateDocument(order);
    // this.isFactureNumber = true;

    // }
  }

  removeDocument(order, ref) {
    order.type = null;
    order.documents = order.documents.filter(el => el.ref != ref);
    order.daysToPay = order.daysToPay - 10; //
    delete order.factureNumber;
    delete order.deadlineToPay;
    this.updateDocument(order);
    // this.isFactureNumber = false;
  }


  updateDocument(order) {
    this.authService.updateOrder(order).then(res => {
      // this.getOrders();
      this.newDocument = {};
    })
  }

  //scroll table
  track(event) {
    this.scrollTop = event;
  }

  editOrder(order) {
    this.getOrders();
    this.newOrder = Object.assign({}, order);
    this.showModal = true;
  }

  showFilters() {
    if (this.filterSwitcher) {
      this.filterSwitcher = false;
    } else {
      this.filterSwitcher = true;
    }
  }
}
