import { Component, OnInit } from '@angular/core';
import {AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from "@angular/fire/storage";
import {Observable} from "rxjs";
import {AuthService} from "../../../shared/services/auth.service";

@Component({
  selector: 'app-additional-spending',
  templateUrl: './additional-spending.component.html',
  styleUrls: ['./additional-spending.component.css']
})
export class AdditionalSpendingComponent implements OnInit {

  public accounts: any;
  public scrollTop: number;
  public newAccount: any = {};
  public currentYears: any = [];
  public currentMonths: any = [];
  public filter: string;
  public currentYear: any =  new Date().getFullYear();
  public currentMonth: any =  new Date().getMonth() + 1;
  public yearFilter: any = this.currentYear;
  public monthFilter: any = this.currentMonth;
  public newDocument: any = {};
  public accountFilter: any;
  public userId: any;

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  showModal: boolean = false;
  showConfirm: boolean = false;
  showDocuments: boolean = false;
  filterSwitcher = false;

  constructor(private afStorage: AngularFireStorage, public authService: AuthService) { }

  ngOnInit() {

    this.userId = this.authService.getCurrentUser().uid;

    this.getYears();
    this.getMonths();
    this.getAdditionalAccounts();
  }

  //add new account
  addAdditionalAccounts(){
    // debugger

    // let checkForYear = false;
    if ( !!this.newAccount.name &&
      !!this.newAccount.date &&
      !!this.newAccount.summ) {
        this.authService.addAdditionalAccounts(this.newAccount).then(res => {
          this.getAdditionalAccounts();
          this.newAccount = {};
          this.showModal = false;
        });

    }
  }

  //update account
  updateAdditionalAccount(account) {
    this.authService.updateAdditionalAccounts(account).then(res => {
      this.getAdditionalAccounts();
      this.newAccount = {};
      this.newDocument = {};
      this.showModal = false;
    })
  }

  //remove account
  removeAdditionalAccount(account) {
    this.authService.removeAdditionalAccounts(account).then(res => {
      this.newAccount = {};
      this.getAdditionalAccounts();
      this.showConfirm = false;
    })
  }

  //edit account
  editAdditionalAccount(account) {
    this.getAdditionalAccounts();
    this.newAccount = Object.assign({}, account);
    this.showModal = true;
  }

  //get additional accounts
  getAdditionalAccounts(){
    this.authService.getAdditionalAccounts().get().then((item: any) => {
      this.accounts = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
      this.accountFilter = [...this.accounts];
      this.changeFilter();
    });
  }

  //remove document
  removeDocument(account, ref){
    account.documents = account.documents.filter(el => el.ref != ref);
    this.updateDocument(account);
  }

  //update document
  updateDocument(account) {
    this.authService.updateAdditionalAccounts(account).then(res => {
      this.getAdditionalAccounts();
      this.newDocument = {};
    })
  }

  //upload document
  upload(event, account) {
    const id = Math.random().toString(36).substring(2);
    this.ref = this.afStorage.ref(id);
    this.task = this.ref.put(event.target.files[0]);
    this.uploadProgress = this.task.percentageChanges();
    this.task.then(res => {
      this.ref.getDownloadURL().subscribe(res => {
        this.newDocument.ref = res;
      });
    });
  }

  //add document
  addDocument(account){
    if(!account.documents) account.documents = [];
    if(this.userId === 'kb8TZYNLqGQLQuGySOHR5Cahvsw2'){
      account.status = 'sent'
    }
    if (this.newDocument.name && this.newDocument.ref) {
      account.documents.push(this.newDocument);
      this.updateDocument(account);
    }
  }

  //search method
  changeFilter(search?) {
    // let toDate = new Date(this.toDate).getTime();
    // let fromDate = new Date(this.fromDate).getTime();
    // debugger
    this.accounts = this.accountFilter.filter(item => {
      // let fromDateItem = new Date(item.fromDate).getTime();
      // let searchFiled = item.NIP + item.price + item.distToFreight + item.distWithFreight + item.fromDate + item.toDate + item.fromPlace + item.toPlace;
      // if(toDate && fromDate) {
      //   if (fromDateItem < fromDate || fromDateItem > toDate) {
      //     return false;
      //   }
      // }
      if (this.yearFilter != 'all') {
        // debugger
        if (item.date.substr(0, 4) != this.yearFilter) {
          return false;
        }
      }
      if (this.monthFilter != 'all') {
        // debugger
        if (item.date.substr(5, 2) != this.monthFilter) {
          return false;
        }
      }

      if (search) {
        let searchFiled = item.name + item.date + item.summ;
        if (searchFiled.toLowerCase().indexOf(search.toLowerCase()) == -1){
          return false;
        }
      }
      return true;

    });
    // this.countSallary();
  }

  //get current years
  getYears () {
    let someYear = this.currentYear;
    for (let i = 0; i <= 10; i++) {
      this.currentYears.push(someYear);
      --someYear;
    }
  }

  //get current months
  getMonths () {
    for (let i = 1; i <= 12; i++) {
      if(i < 10) {
        this.currentMonths.push(String(0) + String(i));
      } else {
        this.currentMonths.push(String(i));
      }

    }
  }


  //scroll table
  track(event){
    this.scrollTop  = event;
  }

  showFilters() {
    if (this.filterSwitcher) {
      this.filterSwitcher = false;
    } else {
      this.filterSwitcher = true;
    }
  }
}
