import { Component, OnInit } from '@angular/core';
import {AngularFireStorageReference, AngularFireUploadTask} from "@angular/fire/storage";
import {Observable} from "rxjs";
import {AuthService} from "../../shared/services/auth.service";
import * as firebase from "firebase";
import {AngularFireAuth} from "@angular/fire/auth";

@Component({
  selector: 'app-logists',
  templateUrl: './logists.component.html',
  styleUrls: ['./logists.component.css']
})
export class LogistsComponent implements OnInit {
  public logists: any;
  public newLogist: any = {};
  public userId: any;
  public scrollTop: number;

  filterSwitcher = false;
  showModal: boolean = false;
  showConfirm: boolean = false;

  constructor(public authService: AuthService, public afAuth: AngularFireAuth) { }

  ngOnInit() {
    this.userId = this.authService.getCurrentUser().uid;
    this.getLogists();
  }

  ///////////logist methods
  //get all logists
  getLogists(){
    this.authService.getLogists().get().then((item: any) => {
      this.logists = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
    });
  }

  //add new logist
  addLogist(){
    if ( !!this.newLogist.name &&
      !!this.newLogist.login &&
      !!this.newLogist.password) {

      this.afAuth.auth.createUserWithEmailAndPassword(this.newLogist.login, this.newLogist.password).then((user: any) =>{
        this.newLogist.id = user.user.uid;
        this.authService.addLogist(this.newLogist).then(res => { //
          this.getLogists(); //
          this.newLogist = {};
          this.showModal = false;
        });
      })
        .catch((error) => {window.alert(error.message);});

    }
    //also add new logist to app users
  }

  //edit logist
  editLogist(logist) {
    this.getLogists();
    this.newLogist = Object.assign({}, logist);
    this.showModal = true;
  }

  //update logist
  updateLogist(logist) {
    this.authService.updateLogist(logist).then(res => {
      this.getLogists();
      this.newLogist = {};
      this.showModal = false;
    })
  }

  //remove logist
  removeLogist(logist) {
    this.authService.removeLogist(logist).then(res => {
      this.newLogist = {};
      this.getLogists();
      this.showConfirm = false;
    })
  }

  //scroll table
  track(event){
    this.scrollTop  = event;
  }

  showFilters() {
    if (this.filterSwitcher) {
      this.filterSwitcher = false;
    } else {
      this.filterSwitcher = true;
    }
  }

}
