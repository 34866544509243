import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
// Required components for which route services to be activated
import {SignInComponent} from '../../components/sign-in/sign-in.component';
import {SignUpComponent} from '../../components/sign-up/sign-up.component';
import {DashboardComponent} from '../../components/dashboard/dashboard.component';
import {ForgotPasswordComponent} from '../../components/forgot-password/forgot-password.component';
import {VerifyEmailComponent} from '../../components/verify-email/verify-email.component';
// Import canActivate guard services
import {AuthGuard} from '../../shared/guard/auth.guard';
import {SecureInnerPagesGuard} from '../../shared/guard/secure-inner-pages.guard';
import {OrdersComponent} from '../../components/orders/orders.component';
import {CarsComponent} from '../../components/cars/cars.component';
import {DriversComponent} from '../../components/drivers/drivers.component';
import {AdminOrdersComponent} from "../../components/admin-orders/admin-orders.component";
import {AcountantOrdersComponent} from '../../components/acauntant-orders/acountant-orders.component';
import {AccountantGuard} from '../guard/accountant.guard';
import {LogistsComponent} from "../../components/logists/logists.component";
import {AccountingComponent} from "../../components/accounting/accounting.component";
import {MonthSpendingComponent} from "../../components/accounting/month-spending/month-spending.component";
import {YearSpendingComponent} from "../../components/accounting/year-spending/year-spending.component";
import {FuelSpendingComponent} from "../../components/accounting/fuel-spending/fuel-spending.component";
import {SalariesComponent} from "../../components/accounting/salaries/salaries.component";
import {AdditionalSpendingComponent} from "../../components/accounting/additional-spending/additional-spending.component";
import {DriverSalaryComponent} from "../../components/accounting/salaries/driver-salary/driver-salary.component";
import {LogistSalaryComponent} from "../../components/accounting/salaries/logist-salary/logist-salary.component";
import {AccountantSalaryComponent} from "../../components/accounting/salaries/accountant-salary/accountant-salary.component";
import {TaxesComponent} from "../../components/accounting/taxes/taxes.component";
import {AccountantsComponent} from "../../components/accountants/accountants.component";
import {ScheduleComponent} from "../../components/accounting/schedule/schedule.component";

// Include route guard in routes array
const routes: Routes = [
  {path: '', redirectTo: '/sign-in', pathMatch: 'full'},
  {path: 'sign-in', component: SignInComponent, canActivate: [SecureInnerPagesGuard]},
  {path: 'register-user', component: SignUpComponent, canActivate: [SecureInnerPagesGuard]},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'cars', component: CarsComponent, canActivate: [AuthGuard]},
  {path: 'drivers', component: DriversComponent, canActivate: [AuthGuard]},
  {path: 'logists', component: LogistsComponent, canActivate: [AuthGuard]},
  {path: 'accountants', component: AccountantsComponent, canActivate: [AuthGuard]},
  {path: 'orders', component: OrdersComponent, canActivate: [AuthGuard]},
  {path: 'admin-orders', component: AdminOrdersComponent, canActivate: [AuthGuard]},
  {path: 'accounting', component: AccountingComponent, canActivate: [AuthGuard], children: [
    {path: 'month-spending', component: MonthSpendingComponent, canActivate: [AuthGuard]},
    {path: 'year-spending', component: YearSpendingComponent, canActivate: [AuthGuard]},
    {path: 'fuel-spending', component: FuelSpendingComponent, canActivate: [AuthGuard]},
    {path: 'salaries', component: SalariesComponent, canActivate: [AuthGuard], children: [
        {path: 'driver-salary', component: DriverSalaryComponent, canActivate: [AuthGuard]},
        {path: 'logist-salary', component: LogistSalaryComponent, canActivate: [AuthGuard]},
        {path: 'accountant-salary', component: AccountantSalaryComponent, canActivate: [AuthGuard]}
      ]},
    {path: 'additional-spending', component: AdditionalSpendingComponent, canActivate: [AuthGuard]},
    {path: 'taxes', component: TaxesComponent, canActivate: [AuthGuard]},
    {path: 'schedule', component: ScheduleComponent, canActivate: [AuthGuard]}
    ]},
  {path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [SecureInnerPagesGuard]},
  {path: 'verify-email-address', component: VerifyEmailComponent, canActivate: [SecureInnerPagesGuard]},
  {path: 'accountant', component: AcountantOrdersComponent, canActivate: [AccountantGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    /** For debugging router (default false)**/
    enableTracing: false,
    preloadingStrategy: PreloadAllModules,
    useHash: true
  })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
