import { Component, OnInit } from '@angular/core';
import {AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from "@angular/fire/storage";
import {Observable} from "rxjs";
import {AuthService} from "../../../../shared/services/auth.service";

@Component({
  selector: 'app-logist-salary',
  templateUrl: './logist-salary.component.html',
  styleUrls: ['./logist-salary.component.css']
})
export class LogistSalaryComponent implements OnInit {

  public scrollTop: number;
  public salaries: any;
  public logists: any = [];
  public newSalary: any = {};
  public filter: string;
  public currentYears: any = [];
  public currentMonths: any = [];
  public currentYear: any =  new Date().getFullYear();
  public currentMonth: any =  new Date().getMonth() + 1;
  public yearFilter: any = this.currentYear;
  public monthFilter: any = this.currentMonth;
  public accountFilter: any;
  public logistFilter: any = 'all';
  public userId: any;

  filterSwitcher = false;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  showModal: boolean = false;
  showConfirm: boolean = false;

  constructor(private afStorage: AngularFireStorage, public authService: AuthService) { }

  ngOnInit() {
    this.userId = this.authService.getCurrentUser().uid;

    this.getLogists();
    this.getYears();
    this.getMonths();
    this.getLogistsSalary();
  }

  //get logists
  getLogists() {
    this.authService.getLogists().get().then((item: any) => {
      this.logists = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
    });
  }

  //edit salary
  editLogistSalary(salary) {
    this.getLogistsSalary();
    this.newSalary = Object.assign({}, salary);
    this.showModal = true;
  }

  //get logists salary
  getLogistsSalary(){
    this.authService.getLogistsSalary().get().then((item: any) => {
      this.salaries = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
      this.accountFilter = [...this.salaries];
      this.changeFilter();
    });
  }

  //add new salary
  addLogistSalary(){
    // debugger

    // let checkForYear = false;
    if ( !!this.newSalary.logist &&
      !!this.newSalary.date &&
      !!this.newSalary.summ) {
      this.authService.addLogistSalary(this.newSalary).then(res => {
        this.getLogistsSalary();
        this.newSalary = {};
        this.showModal = false;
      });

    }
  }

  //update salary
  updateLogistSalary(salary) {
    this.authService.updateLogistSalary(salary).then(res => {
      this.getLogistsSalary();
      this.newSalary = {};
      // this.newDocument = {};
      this.showModal = false;
    })
  }

  //remove salary
  removeLogistSalary(salary) {
    this.authService.removeLogistSalary(salary).then(res => {
      this.newSalary = {};
      this.getLogistsSalary();
      this.showConfirm = false;
    })
  }

  //search method
  changeFilter(search?) {
    // let toDate = new Date(this.toDate).getTime();
    // let fromDate = new Date(this.fromDate).getTime();
    // debugger
    this.salaries = this.accountFilter.filter(item => {
      // let fromDateItem = new Date(item.fromDate).getTime();
      // let searchFiled = item.NIP + item.price + item.distToFreight + item.distWithFreight + item.fromDate + item.toDate + item.fromPlace + item.toPlace;
      // if(toDate && fromDate) {
      //   if (fromDateItem < fromDate || fromDateItem > toDate) {
      //     return false;
      //   }
      // }
      if (this.yearFilter != 'all') {
        // debugger
        if (item.date.substr(0, 4) != this.yearFilter) {
          return false;
        }
      }
      if (this.monthFilter != 'all') {
        // debugger
        if (item.date.substr(5, 2) != this.monthFilter) {
          return false;
        }
      }
      if (this.logistFilter != 'all') {
        if (item.logist  !== this.logistFilter) {
          return false;
        }
      }

      if (search) {
        let searchFiled = item.logist + item.date + item.summ;
        if (searchFiled.toLowerCase().indexOf(search.toLowerCase()) == -1){
          return false;
        }
      }
      return true;

    });
    // this.countSallary();
  }

  //get current years
  getYears () {
    let someYear = this.currentYear;
    for (let i = 0; i <= 10; i++) {
      this.currentYears.push(someYear);
      --someYear;
    }
  }

  //get current months
  getMonths () {
    for (let i = 1; i <= 12; i++) {
      if(i < 10) {
        this.currentMonths.push(String(0) + String(i));
      } else {
        this.currentMonths.push(String(i));
      }

    }
  }

  //scroll table
  track(event){
    this.scrollTop  = event;
  }

  showFilters() {
    if (this.filterSwitcher) {
      this.filterSwitcher = false;
    } else {
      this.filterSwitcher = true;
    }
  }

}
