import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../shared/services/auth.service';
import {AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from '@angular/fire/storage';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  public cars: any = [];
  public drivers: any = [];
  public statuses: any = [
    'wait',
    'inRoad',
    'finish',
  ];
  public newOrder: any = {};
  public newDocument: any = {};
  public orders: any = [];
  public logist: any;
  public ordersFilter: any;
  public userId: any;

  public filter: string;
  public x: any = new Date;
  public fromDate: any = this.formatDate(new Date(this.x.getFullYear(), this.x.getMonth(), 1));
  public toDate: any = this.formatDate(new Date(this.x.getFullYear(), this.x.getMonth() + 1, 0));
  public carFilter: any = 'all';
  public driverFilter: any = 'all';
  public scrollTop: number;

  filterSwitcher = false;
  showModal: boolean = false;
  showZp: boolean = false;
  showConfirm: boolean = false;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;

  constructor(private afStorage: AngularFireStorage, public authService: AuthService) { }

  ngOnInit() {
    this.userId = this.authService.getCurrentUser().uid;
    this.logist = this.authService.getCurrentUser();

    this.authService.getCars().get().then((item: any) => {
      this.cars = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
    });

    this.authService.getDrivers().get().then((item: any) => {
      this.drivers = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
    });
    this.getOrders();
  }

  track(event){
    this.scrollTop  = event;
  }

  formatDate (date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  getOrders() {
    this.authService.getOrders().get().then((item: any) => {
      this.orders = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      }).filter(el => el.createdBy === this.userId);
      this.orders.sort((a, b) => {
        return <any>new Date(b.fromDate) - <any>new Date(a.fromDate);
      });
      this.ordersFilter = [...this.orders];
      this.changeFilter();
      this.countSallary();
    });
  }

  upload(event, order) {
    const id = Math.random().toString(36).substring(2);
    this.ref = this.afStorage.ref(id);
    this.task = this.ref.put(event.target.files[0]);
    this.uploadProgress = this.task.percentageChanges();
    this.task.then(res => {
      this.ref.getDownloadURL().subscribe(res => {
        this.newDocument.ref = res;
      });
    });
  }

  addDocument(order){
    // debugger
    if(!order.documents) order.documents = [];
    if(this.userId === 'kb8TZYNLqGQLQuGySOHR5Cahvsw2'){
      order.status = 'sent'
    }
    if (this.newDocument.name && this.newDocument.ref) {
      order.documents.push(this.newDocument);
      this.updateDocument(order);
    }

  }

  removeDocument(order, ref){
    order.documents = order.documents.filter(el => el.ref != ref);
    this.updateDocument(order);
  }

  getDriverById(id){
    return this.drivers.find(el => el.id == id).name;
  }
  getCarById(id){
    return this.cars.find(el => el.id == id).model;
  }
  getDate(date){
    return new Date(date);
  }

  addOrder(){
    if ( !!this.newOrder.NIP &&
      !!this.newOrder.car &&
      !!this.newOrder.distToFreight &&
      !!this.newOrder.distWithFreight &&
      !!this.newOrder.driver &&
      !!this.newOrder.fromDate &&
      !!this.newOrder.fromPlace &&
      !!this.newOrder.price &&
      !!this.newOrder.toDate &&
      !!this.newOrder.daysToPay &&
      !!this.newOrder.inVoice &&
      !!this.newOrder.toPlace) {
      this.newOrder['createdBy'] = this.userId;
      this.authService.addOrder(this.newOrder).then(res => {
        this.getOrders();
        this.newOrder = {};
        this.showModal = false;
      });
    }
  }

  updateOrder(order) {
    this.authService.updateOrder(order).then(res => {
      this.getOrders();
      this.newOrder = {};
      this.newDocument = {};
      this.showModal = false;
    })
  }

  updateDocument(order) {

    this.authService.updateOrder(order).then(res => {
      this.getOrders();
      this.newDocument = {};
    })
  }

  removeOrder(order) {
    this.authService.removeOrder(order).then(res => {
      this.newOrder = {};
      this.getOrders();
      this.showConfirm = false;
    })
  }

  editOrder(order) {
    this.getOrders();
    this.newOrder = Object.assign({}, order);
    this.showModal = true;
  }

  changeFilter(search?) {
    let toDate = new Date(this.toDate).getTime();
    let fromDate = new Date(this.fromDate).getTime();
    this.orders = this.ordersFilter.filter(item => {

      let fromDateItem = new Date(this.formatDate(new Date(item.fromDate.substr(0,4), item.fromDate.substr(5,2) - 1, item.fromDate.substr(8,2)))).getTime();
      let toDateItem = new Date(this.formatDate(new Date(item.toDate.substr(0,4), item.toDate.substr(5,2) - 1, item.toDate.substr(8,2)))).getTime();

      if(toDate && fromDate) {
        if ((fromDateItem < fromDate || fromDateItem > toDate) && (toDateItem < fromDate || toDateItem > toDate)) {
          return false;
        }
      }
      if (this.carFilter != 'all') {
        if (item.car !== this.carFilter) {
          return false;
        }
      }
      if (this.driverFilter != 'all') {
        if (item.driver  !== this.driverFilter) {
          return false;
        }
      }
      if (search) {
        let searchFiled = item.NIP + item.campaignName + item.car  + item.createdBy  + item.factureNumber  + item.driver + item.price + item.distToFreight + item.distWithFreight + item.type + item.fromDate + item.toDate + item.fromPlace + item.toPlace + item.inVoice;
        if (searchFiled.toLowerCase().indexOf(search.toLowerCase()) == -1){
          return false;
        }
      }
      return true;

    });
    this.countSallary();
  }

  countSallary(){
    this.logist.cars = {};
    this.logist.fullSalary = 0;
    this.logist.allSalary = 0;
    this.logist.allDistance = 0;
    this.orders.forEach(order=>{
      this.logist.allSalary += order.price;
      this.logist.allDistance += (order.distWithFreight + order.distToFreight)
    })
      // this.orders.forEach(order=>{
      //   if(!this.logist.cars[order.car]){
      //     this.logist.cars[order.car] = {
      //       all: 0,
      //       percent: 0,
      //       distWithFreight: 0,
      //       distToFreight: 0,
      //       spendForPatrol: 0
      //     }
      //     this.cars.forEach(car => {
      //       if(car.id == order.car){
      //         this.logist.cars[order.car].model = car.model;
      //       }
      //     })
      //   }
      //   this.logist.cars[order.car].all += order.price;
      //   this.logist.cars[order.car].spendForPatrol += ((order.distWithFreight + order.distToFreight) * 0.15);
      //   this.logist.cars[order.car].distWithFreight += order.distWithFreight;
      //   this.logist.cars[order.car].distToFreight += order.distToFreight;
      //   // let rate = order.price / (order.distWithFreight + order.distToFreight);
      //   // let percent = 0;
      //   // if (rate >= 0.5){
      //   //   percent = 10
      //   // }else if (rate >= 0.45){
      //   //   percent = 7
      //   // }else if (rate >= 0.4){
      //   //   percent = 5
      //   // }
      //   // this.logist.cars[order.car].percent += order.price / 100 * percent;
      //   // if(this.logist.cars[order.car].all >= 4500) {
      //   //   this.logist.cars[order.car].addition = 150;
      //   // }
      // });
      let cars = []
      // for(let car in this.logist.cars){
      //   cars.push(this.logist.cars[car]);
      //   this.logist.fullSalary += (this.logist.cars[car].percent + this.logist.cars[car].addition);
      // }
      this.logist.cars = cars;
  }

  showFilters() {
    if (this.filterSwitcher) {
      this.filterSwitcher = false;
    } else {
      this.filterSwitcher = true;
    }
  }


}
