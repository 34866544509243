import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../shared/services/auth.service";
import {AngularFireAuth} from "@angular/fire/auth";

@Component({
  selector: 'app-accountants',
  templateUrl: './accountants.component.html',
  styleUrls: ['./accountants.component.css']
})
export class AccountantsComponent implements OnInit {

  public accountants: any;
  public newAccountant: any = {};
  public userId: any;
  public scrollTop: number;

  filterSwitcher = false;
  showModal: boolean = false;
  showConfirm: boolean = false;

  constructor(public authService: AuthService, public afAuth: AngularFireAuth) { }

  ngOnInit() {
    this.userId = this.authService.getCurrentUser().uid;

    this.getAccountants();
  }

  //edit accountant
  editAccountant(accountant) {
    this.getAccountants();
    this.newAccountant = Object.assign({}, accountant);
    this.showModal = true;
  }

  //get all accountants
  getAccountants(){
    this.authService.getAccountants().get().then((item: any) => {
      this.accountants = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
    });
  }

  //add new accountant
  addAccountant(){
    if ( !!this.newAccountant.name &&
      !!this.newAccountant.login &&
      !!this.newAccountant.password) {

      let x = JSON.parse(localStorage.getItem('user'));


      this.afAuth.auth.createUserWithEmailAndPassword(this.newAccountant.login, this.newAccountant.password).then((user: any) => {
        // debugger
        this.newAccountant.uid = user.user.uid;
        this.authService.addAccountant(this.newAccountant).then(res => { //
          this.getAccountants(); //
          this.newAccountant = {};
          this.showModal = false;
        });
        localStorage.setItem('user', JSON.stringify(x));
      })
        .catch((error) => {window.alert(error.message);});


    }
  }

  //update accountant
  updateAccountant(accountant) {
    this.authService.updateAccountant(accountant).then(res => {
      this.getAccountants();
      this.newAccountant = {};
      this.showModal = false;
    })
  }

  //remove accountant
  removeAccountant(accountant) {
    this.authService.removeAccountant(accountant).then(res => {
      this.newAccountant = {};
      this.getAccountants();
      this.showConfirm = false;
    })
  }

  //scroll table
  track(event){
    this.scrollTop  = event;
  }

  showFilters() {
    if (this.filterSwitcher) {
      this.filterSwitcher = false;
    } else {
      this.filterSwitcher = true;
    }
  }

}
