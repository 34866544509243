import { Component, OnInit } from '@angular/core';
import {AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from "@angular/fire/storage";
import {Observable} from "rxjs";
import {AuthService} from "../../../../shared/services/auth.service";

@Component({
  selector: 'app-driver-salary',
  templateUrl: './driver-salary.component.html',
  styleUrls: ['./driver-salary.component.css']
})
export class DriverSalaryComponent implements OnInit {

  public driverSalaryPerDay: number = 40;
  public schedules: any = [];
  public scrollTop: number;
  public salaries: any = [];
  public testArray: any = [];
  public drivers: any = [];
  public newSalary: any = {};
  public filter: string;
  public currentYears: any = [];
  public currentMonths: any = [];
  public currentYear: any =  new Date().getFullYear();
  public currentMonth: any =  new Date().getMonth() + 1;
  public yearFilter: any = this.currentYear;
  public monthFilter: any = this.currentMonth;
  public accountFilter: any;
  public driverFilter: any = 'all';
  public userId: any;
  public currentDatee: any = new Date().getTime();


  filterSwitcher = false;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  showModal: boolean = false;
  showConfirm: boolean = false;
  // showDocuments: boolean = false;

  constructor(public authService: AuthService) { }

  ngOnInit() {
    this.userId = this.authService.getCurrentUser().uid;

    this.getDrivers();
    this.getYears();
    this.getMonths();
    this.getSchedules();

  }

  //get schedules
  getSchedules(){
    // let something = false;
    this.authService.getSchedules().get().then((item: any) => {
      this.schedules = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });

      // let firstMonth = true;
      for(let firstMonth = true,  i = 0; i < this.schedules.length; i++,  firstMonth = true){
        //calculate all salaries if there is 'endWork'
        if (this.schedules[i].endWork) {
          //get difference of months
          let differenceOfMonths = Math.abs(this.monthDiff(
            new Date(Number(this.schedules[i].startWork.substr(0,4)), Number(this.schedules[i].startWork.substr(5,2)), Number(this.schedules[i].startWork.substr(8,2))),
            new Date(Number(this.schedules[i].endWork.substr(0,4)), Number(this.schedules[i].endWork.substr(5,2)), Number(this.schedules[i].endWork.substr(8,2)))
          ));
          // console.log(differenceOfMonths+1);
          // console.log(this.schedules);
          for(let j = 0; j <= differenceOfMonths; j++){
            // debugger
            //if there is only one month
            if(differenceOfMonths === 0){
              let lastDayOfTheCurrentMonth = new Date(Number(this.schedules[i].endWork.substr(0, 4)), Number(this.schedules[i].endWork.substr(5, 2)), 0).toLocaleDateString("en-US");
              let summm = ((new Date(Number(this.schedules[i].endWork.substr(0,4)), Number(this.schedules[i].endWork.substr(5,2)), Number(this.schedules[i].endWork.substr(8,2))).getTime() - new Date(Number(this.schedules[i].startWork.substr(0,4)), Number(this.schedules[i].startWork.substr(5,2)), Number(this.schedules[i].startWork.substr(8,2))).getTime())/(1000*3600*24)+1)*this.driverSalaryPerDay;
              let salary = {
                "driver": this.schedules[i].driver,
                "date": lastDayOfTheCurrentMonth,
                "summ": summm
              };
              // console.log(salary);
              // console.log('//end//');
              this.testArray.push(salary);
              //here
              //in the end
              if((i+1) == this.schedules.length){
                // console.log('loading');
                // console.log(this.testArray);
                // this.accountFilter = [...this.testArray];
                this.addSameMonths();
              }
              //if there is more than 1 month
            } else if (differenceOfMonths > 0) {
              // debugger
              //calculate first month
              if(firstMonth){
                let lastDayOfTheCurrentMonth = new Date(Number(this.schedules[i].startWork.substr(0, 4)), Number(this.schedules[i].startWork.substr(5, 2)), 0).toLocaleDateString("en-US");
                let summm = (Math.ceil(((new Date(Number(this.schedules[i].startWork.substr(0, 4)), Number(this.schedules[i].startWork.substr(5, 2)), 0).getTime() - new Date(Number(this.schedules[i].startWork.substr(0,4)), Number(this.schedules[i].startWork.substr(5,2)) - 1, Number(this.schedules[i].startWork.substr(8,2))).getTime())/(1000*3600*24)))+1) * this.driverSalaryPerDay;
                let salary = {
                  "driver": this.schedules[i].driver,
                  "date": lastDayOfTheCurrentMonth,
                  "summ": summm
                };
                // console.log(salary);
                this.testArray.push(salary);
                firstMonth = false;
              } else {
                //if the last one month
                if(((Number(this.schedules[i].startWork.substr(5, 2)) + j) === (Number(this.schedules[i].endWork.substr(5, 2)))) || (((Number(this.schedules[i].startWork.substr(5, 2)) + j) == 13) && ((this.schedules[i].endWork.substr(5, 2)) == '01'))){
                  let lastDayOfTheCurrentMonth = new Date(Number(this.schedules[i].endWork.substr(0, 4)), Number(this.schedules[i].endWork.substr(5, 2)), 0).toLocaleDateString("en-US");
                  let summm = Number(this.schedules[i].endWork.substr(8, 2)) * this.driverSalaryPerDay;
                  let salary = {
                    "driver": this.schedules[i].driver,
                    "date": lastDayOfTheCurrentMonth,
                    "summ": summm
                  };
                  // console.log(salary);
                  // console.log('//end//');
                  this.testArray.push(salary);
                  //here
                  if((i+1) == this.schedules.length){
                    // console.log('loading');
                    // console.log(this.testArray);
                    // this.accountFilter = [...this.testArray];
                    this.addSameMonths();
                  }
                } else {
                  let lastDayOfTheCurrentMonth = new Date(Number(this.schedules[i].startWork.substr(0, 4)), Number(this.schedules[i].startWork.substr(5, 2)) + j, 0).toLocaleDateString("en-US");
                  let summm = Math.floor(((new Date(Number(this.schedules[i].startWork.substr(0, 4)), Number(this.schedules[i].startWork.substr(5, 2)) + j, 0).getTime()) - (new Date(Number(this.schedules[i].startWork.substr(0, 4)), Number(this.schedules[i].startWork.substr(5, 2)) + j - 1, 1).getTime()))/(1000*3600*24)+1) * this.driverSalaryPerDay;
                  let salary = {
                    "driver": this.schedules[i].driver,
                    "date": lastDayOfTheCurrentMonth,
                    "summ": summm
                  };
                  // console.log(salary);
                  this.testArray.push(salary);
                }
              }
            }
          }
        }
      }
    });

    // console.log(this.testArray);
    // console.log(this.salaries);
  }

  //get drivers
  getDrivers() {
    this.authService.getDrivers().get().then((item: any) => {
      this.drivers = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
    });
  }
  //add same months
  addSameMonths(){
    // debugger
    let ob = {};
    // debugger
    // console.log(this.testArray);

    for(let i = 0; i < this.testArray.length; i++){
      if(this.testArray[i].date.substr(1,1) === '/'){
        // console.log('i catch / in ' + this.testArray[i].date + '-' + this.testArray[i].driver);
        this.testArray[i].date = '0' + this.testArray[i].date;
      }
    }

    // console.log(this.testArray);
    this.salaries = this.testArray.reduce(function (r, e) {
      let key = e.driver + '|' + e.date;
      if(!ob[key]) {
        ob[key] = e;
        r.push(ob[key]);
      } else {
        ob[key].summ += e.summ;
      }
      return r;
    }, []);

    // console.log(this.accountFilter);
    this.accountFilter = [...this.salaries];
    // console.log(this.accountFilter);
    // console.log(this.salaries);
    this.changeFilter();
  }

  // //edit salary
  // editDriverSalary(salary) {
  //   this.getDriversSalary();
  //   this.newSalary = Object.assign({}, salary);
  //   this.showModal = true;
  // }

  // //get drivers salary
  // getDriversSalary(){
  //   this.authService.getDriversSalary().get().then((item: any) => {
  //     this.salaries = item.docs.map(doc => {
  //       const id = doc.id;
  //       doc = doc.data();
  //       doc.id = id;
  //       return doc;
  //     });
  //     this.accountFilter = [...this.salaries];
  //     this.changeFilter();
  //   });
  // }

  // //update salary
  // updateDriverSalary(salary) {
  //   this.authService.updateDriverSalary(salary).then(res => {
  //     this.getDriversSalary();
  //     this.newSalary = {};
  //     // this.newDocument = {};
  //     this.showModal = false;
  //   })
  // }

  // //remove salary
  // removeDriverSalary(salary) {
  //   this.authService.removeDriverSalary(salary).then(res => {
  //     this.newSalary = {};
  //     this.getDriversSalary();
  //     this.showConfirm = false;
  //   })
  // }

  // //add new salary
  // addDriverSalary(){
  //   // debugger
  //
  //   // let checkForYear = false;
  //   if ( !!this.newSalary.driver &&
  //     !!this.newSalary.date &&
  //     !!this.newSalary.summ) {
  //     this.authService.addDriverSalary(this.newSalary).then(res => {
  //       this.getDriversSalary();
  //       this.newSalary = {};
  //       this.showModal = false;
  //     });
  //
  //   }
  // }

  //search method
  changeFilter(search?) {
    // let toDate = new Date(this.toDate).getTime();
    // let fromDate = new Date(this.fromDate).getTime();
    // debugger

    // debugger
    // console.log(typeof this.accountFilter);
    this.salaries = this.accountFilter.filter(item => {
      // let fromDateItem = new Date(item.fromDate).getTime();
      // let searchFiled = item.NIP + item.price + item.distToFreight + item.distWithFreight + item.fromDate + item.toDate + item.fromPlace + item.toPlace;
      // if(toDate && fromDate) {
      //   if (fromDateItem < fromDate || fromDateItem > toDate) {
      //     return false;
      //   }
      // }

      if (this.yearFilter != 'all') {
        // debugger
        // if(item.date){
          if (item.date.substr(6, 4) != this.yearFilter) {
            return false;
          }
        // }
      }

      if (this.monthFilter != 'all') {
        // debugger
        // if(item.date){
          if (item.date.substr(0, 2) != this.monthFilter) {
            return false;
          }
        // }
      }
      if (this.driverFilter != 'all') {
        // if(item.driver) {
          if (item.driver  !== this.driverFilter) {
            return false;
          }
        // }
      }

      if (search) {
        let searchFiled = item.driver + item.date + item.summ;
        if (searchFiled.toLowerCase().indexOf(search.toLowerCase()) == -1){
          return false;
        }
      }
      return true;

    });
    // this.countSallary();
  }

  //get current years
  getYears () {
    let someYear = this.currentYear;
    for (let i = 0; i <= 10; i++) {
      this.currentYears.push(someYear);
      --someYear;
    }
  }

  //get current months
  getMonths () {
    for (let i = 1; i <= 12; i++) {
      if(i < 10) {
        this.currentMonths.push(String(0) + String(i));
      } else {
        this.currentMonths.push(String(i));
      }

    }
  }

  //scroll table
  track(event){
    this.scrollTop  = event;
  }

  //difference between two months
  monthDiff(d1, d2) {
    // let months;
    // months = (d2.getFullYear() - d1.getFullYear()) * 12;
    // months -= d1.getMonth() + 1;
    // months += d2.getMonth();
    // return months <= 0 ? 0 : months;
    return d1.getMonth() - d2.getMonth() +
      (12 * (d1.getFullYear() - d2.getFullYear()));
  }

  showFilters() {
    if (this.filterSwitcher) {
      this.filterSwitcher = false;
    } else {
      this.filterSwitcher = true;
    }
  }
}
