import { Component, OnInit } from '@angular/core';
import {AngularFireStorageReference, AngularFireUploadTask} from "@angular/fire/storage";
import {Observable} from "rxjs";
import {AuthService} from "../../../../shared/services/auth.service";

@Component({
  selector: 'app-accountant-salary',
  templateUrl: './accountant-salary.component.html',
  styleUrls: ['./accountant-salary.component.css']
})
export class AccountantSalaryComponent implements OnInit {

  public scrollTop: number;
  public salaries: any;
  public accountants: any = [];
  public newSalary: any = {};
  public filter: string;
  public currentYears: any = [];
  public currentMonths: any = [];
  public currentYear: any =  new Date().getFullYear();
  public currentMonth: any =  new Date().getMonth() + 1;
  public yearFilter: any = this.currentYear;
  public monthFilter: any = this.currentMonth;
  public accountFilter: any;
  public userId: any;
  public accountantFilter: any = 'all';

  filterSwitcher = false;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  showModal: boolean = false;
  showConfirm: boolean = false;

  constructor(public authService: AuthService) { }

  ngOnInit() {
    this.userId = this.authService.getCurrentUser().uid;

    this.getYears();
    this.getMonths();
    this.getAccountantSalary();
    this.getAccountants();
  }

  //edit salary
  editAccountantSalary(salary) {
    this.getAccountantSalary();
    this.newSalary = Object.assign({}, salary);
    this.showModal = true;
  }

  //get accountant salary
  getAccountantSalary(){
    this.authService.getAccountantSalary().get().then((item: any) => {
      this.salaries = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
      this.accountFilter = [...this.salaries];
      this.changeFilter();
    });
  }

  //update salary
  updateAccountantSalary(salary) {
    this.authService.updateAccountantSalary(salary).then(res => {
      this.getAccountantSalary();
      this.newSalary = {};
      // this.newDocument = {};
      this.showModal = false;
    })
  }

  //remove salary
  removeAccountantSalary(salary) {
    this.authService.removeAccountantSalary(salary).then(res => {
      this.newSalary = {};
      this.getAccountantSalary();
      this.showConfirm = false;
    })
  }

  //add new salary
  addAccountantSalary(){
    // debugger

    // let checkForYear = false;
    if (!!this.newSalary.accountant &&
      !!this.newSalary.date &&
      !!this.newSalary.summ) {
      this.authService.addAccountantSalary(this.newSalary).then(res => {
        this.getAccountantSalary();
        this.newSalary = {};
        this.showModal = false;
      });

    }
  }

  //get accountants
  getAccountants() {
    this.authService.getAccountants().get().then((item: any) => {
      this.accountants = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
    });
  }

  //search method
  changeFilter(search?) {
    // let toDate = new Date(this.toDate).getTime();
    // let fromDate = new Date(this.fromDate).getTime();
    // debugger
    this.salaries = this.accountFilter.filter(item => {
      // let fromDateItem = new Date(item.fromDate).getTime();
      // let searchFiled = item.NIP + item.price + item.distToFreight + item.distWithFreight + item.fromDate + item.toDate + item.fromPlace + item.toPlace;
      // if(toDate && fromDate) {
      //   if (fromDateItem < fromDate || fromDateItem > toDate) {
      //     return false;
      //   }
      // }
      if (this.yearFilter != 'all') {
        // debugger
        if (item.date.substr(0, 4) != this.yearFilter) {
          return false;
        }
      }
      if (this.monthFilter != 'all') {
        // debugger
        if (item.date.substr(5, 2) != this.monthFilter) {
          return false;
        }
      }

      if (this.accountantFilter != 'all') {
        if (item.accountant  !== this.accountantFilter) {
          return false;
        }
      }

      if (search) {
        let searchFiled =  item.date + item.summ;
        if (searchFiled.toLowerCase().indexOf(search.toLowerCase()) == -1){
          return false;
        }
      }
      return true;

    });
    // this.countSallary();
  }

  //get current years
  getYears () {
    let someYear = this.currentYear;
    for (let i = 0; i <= 10; i++) {
      this.currentYears.push(someYear);
      --someYear;
    }
  }

  //get current months
  getMonths () {
    for (let i = 1; i <= 12; i++) {
      if(i < 10) {
        this.currentMonths.push(String(0) + String(i));
      } else {
        this.currentMonths.push(String(i));
      }

    }
  }

  //scroll table
  track(event){
    this.scrollTop  = event;
  }

  showFilters() {
    if (this.filterSwitcher) {
      this.filterSwitcher = false;
    } else {
      this.filterSwitcher = true;
    }
  }

}
