import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../shared/services/auth.service";

@Component({
  selector: 'app-month-spending',
  templateUrl: './month-spending.component.html',
  styleUrls: ['./month-spending.component.css']
})
export class MonthSpendingComponent implements OnInit {

  public driverSalaryPerDay: number = 40;
  public schedules: any = [];
  public salaries: any = [];
  public currentYears: any = [];
  public currentMonths: any = [];
  public currentYear: any = new Date().getFullYear();
  public currentMonth: any =  new Date().getMonth() + 1;
  public yearFilter: any = this.currentYear;
  public monthFilter: any = this.currentMonth;
  public filter: string;
  public testArray: any = [];

  filterSwitcher = false;
  public fuelAccounts: any;
  public orders: any;
  public taxes: any;
  public yearAccounts: any;
  public additionalAccounts: any;
  public driversSalary: any = [];
  public logistsSalary: any;
  public accountantsSalary: any;
  public fakeMonths: any = [];
  public months: any = [
    {name: 'Січень', number: '01', fuelAccount: 0, yearAccount: 0, additionalAccount: 0, driversSalary: 0, logistsSalary: 0, accountantsSalary: 0, expectedIncome: 0, receivedIncome: 0, expectedTogether: 0, receivedTogether: 0, taxes: 0},
    {name: 'Лютий', number: '02', fuelAccount: 0, yearAccount: 0, additionalAccount: 0, driversSalary: 0, logistsSalary: 0, accountantsSalary: 0, expectedIncome: 0, receivedIncome: 0, expectedTogether: 0, receivedTogether: 0, taxes: 0},
    {name: 'Березень', number: '03', fuelAccount: 0, yearAccount: 0, additionalAccount: 0, driversSalary: 0, logistsSalary: 0, accountantsSalary: 0, expectedIncome: 0, receivedIncome: 0, expectedTogether: 0, receivedTogether: 0, taxes: 0},
    {name: 'Квітень', number: '04', fuelAccount: 0, yearAccount: 0, additionalAccount: 0, driversSalary: 0, logistsSalary: 0, accountantsSalary: 0, expectedIncome: 0, receivedIncome: 0, expectedTogether: 0, receivedTogether: 0, taxes: 0},
    {name: 'Травень', number: '05', fuelAccount: 0, yearAccount: 0, additionalAccount: 0, driversSalary: 0, logistsSalary: 0, accountantsSalary: 0, expectedIncome: 0, receivedIncome: 0, expectedTogether: 0, receivedTogether: 0, taxes: 0},
    {name: 'Червень', number: '06', fuelAccount: 0, yearAccount: 0, additionalAccount: 0, driversSalary: 0, logistsSalary: 0, accountantsSalary: 0, expectedIncome: 0, receivedIncome: 0, expectedTogether: 0, receivedTogether: 0, taxes: 0},
    {name: 'Липень', number: '07', fuelAccount: 0, yearAccount: 0, additionalAccount: 0, driversSalary: 0, logistsSalary: 0, accountantsSalary: 0, expectedIncome: 0, receivedIncome: 0, expectedTogether: 0, receivedTogether: 0, taxes: 0},
    {name: 'Серпень', number: '08', fuelAccount: 0, yearAccount: 0, additionalAccount: 0, driversSalary: 0, logistsSalary: 0, accountantsSalary: 0, expectedIncome: 0, receivedIncome: 0, expectedTogether: 0, receivedTogether: 0, taxes: 0},
    {name: 'Вересень', number: '09', fuelAccount: 0, yearAccount: 0, additionalAccount: 0, driversSalary: 0, logistsSalary: 0, accountantsSalary: 0, expectedIncome: 0, receivedIncome: 0, expectedTogether: 0, receivedTogether: 0, taxes: 0},
    {name: 'Жовтень', number: '10', fuelAccount: 0, yearAccount: 0, additionalAccount: 0, driversSalary: 0, logistsSalary: 0, accountantsSalary: 0, expectedIncome: 0, receivedIncome: 0, expectedTogether: 0, receivedTogether: 0, taxes: 0},
    {name: 'Листопад', number: '11', fuelAccount: 0, yearAccount: 0, additionalAccount: 0, driversSalary: 0, logistsSalary: 0, accountantsSalary: 0, expectedIncome: 0, receivedIncome: 0, expectedTogether: 0, receivedTogether: 0, taxes: 0},
    {name: 'Грудень', number: '12', fuelAccount: 0, yearAccount: 0, additionalAccount: 0, driversSalary: 0, logistsSalary: 0, accountantsSalary: 0, expectedIncome: 0, receivedIncome: 0, expectedTogether: 0, receivedTogether: 0, taxes: 0},
  ];

  public accountFilter: any;

  constructor(public authService: AuthService) { }

  ngOnInit() {

    this.getYears();
    this.getMonths();

    // debugger
    this.getFuelAccounts();
    this.getYearAccounts();
    this.getAdditionalAccounts();
    this.getTaxes();
    this.getDriversSalary();
    this.getLogistsSalary();
    this.getAccountantSalary();
    this.getOrders();

    this.changeFilter();
    // console.log(this.yearAccounts);
    // console.log(this.additionalAccounts);
    // console.log(this.driversSalary);
    // console.log(this.logistsSalary);
    // console.log(this.accountantsSalary);
  }

  //get fuel accounts
  getFuelAccounts(){
    this.authService.getFuelAccounts().get().then((item: any) => {
      this.fuelAccounts = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
      // this.accountFilter = [...this.fuelAccounts];

      for (let i = 0; i < this.months.length; i++) {
        this.months[i].fuelAccount = 0;
      }
      // this.changeFilter();
      for (let i = 0; i < this.fuelAccounts.length; i++) {
        for (let j = 0; j < this.months.length; j++) {
          if ((this.fuelAccounts[i].date.substr(5, 2) === this.months[j].number) && (this.fuelAccounts[i].date.substr(0, 4) === String(this.currentYear))) {
            this.months[j].fuelAccount += this.fuelAccounts[i].summ;
          }
        }
      }
    });

    // let x = 0;
    // for (let i = 0; i < this.fuelAccounts.length; i++ ) {
    //   x += this.fuelAccounts[i].summ;
    // }
  }

  //get all orders
  getOrders(){
    this.authService.getOrders().get().then((item: any) => {
      this.orders = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });

      for (let i = 0; i < this.months.length; i++) {
        this.months[i].receivedIncome = 0;
        this.months[i].expectedIncome = 0;
      }

      for (let i = 0; i < this.orders.length; i++) {
        for (let j = 0; j < this.months.length; j++) {
          if ((this.orders[i].toDate.substr(5, 2) === this.months[j].number) && (this.orders[i].toDate.substr(0, 4) === String(this.currentYear))) {
            this.months[j].expectedIncome += this.orders[i].price;
            if (this.orders[i].orderPaid) {
              this.months[j].receivedIncome += this.orders[i].price;
            }
          }
        }
      }
      // this.orders.sort((a, b) => {
      //   return <any>new Date(b.fromDate) - <any>new Date(a.fromDate);
      // });
      // this.ordersFilter = [...this.orders];

      // this.countSallary();
    });
  }
  // expectedIncome: 0, receivedIncome: 0, expectedTogether: 0, receivedTogether: 0

  //get year accounts
  getYearAccounts(){
    this.authService.getYearAccounts().get().then((item: any) => {
      this.yearAccounts = item.docs.map(doc => {
        // debugger
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });

      for (let i = 0; i < this.months.length; i++) {
        this.months[i].yearAccount = 0;
      }

      // debugger
      let sum = 0;
      for (let i = 0; i < this.yearAccounts.length; i++) {
        if (this.yearAccounts[i].year === Number(this.currentYear)) {
          sum += Number(this.yearAccounts[i].summ);
        }
      }
      for (let j = 0; j < this.months.length; j++) {
        this.months[j].yearAccount = sum/12;
      }
      // this.accountFilter = [...this.yearAccounts];
    });
  }

  //get additional accounts
  getAdditionalAccounts(){
    this.authService.getAdditionalAccounts().get().then((item: any) => {
      this.additionalAccounts = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });

      for (let i = 0; i < this.months.length; i++) {
        this.months[i].additionalAccount = 0;
      }

      for (let i = 0; i < this.additionalAccounts.length; i++) {
        for (let j = 0; j < this.months.length; j++) {
          if ((this.additionalAccounts[i].date.substr(5, 2) === this.months[j].number) && (this.additionalAccounts[i].date.substr(0, 4) === String(this.currentYear))) {
            this.months[j].additionalAccount += this.additionalAccounts[i].summ;
          }
        }
      }
      // this.accountFilter = [...this.additionalAccounts];
      // this.changeFilter();
    });
  }

  //get taxes
  getTaxes(){
    this.authService.getTaxes().get().then((item: any) => {
      this.taxes = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });

      for (let i = 0; i < this.months.length; i++) {
        this.months[i].taxes = 0;
      }

      for (let i = 0; i < this.taxes.length; i++) {
        for (let j = 0; j < this.months.length; j++) {
          // debugger
          if ((this.taxes[i].date.substr(5, 2) === this.months[j].number) && (this.taxes[i].date.substr(0, 4) === String(this.currentYear))) {
            this.months[j].taxes += this.taxes[i].summ;
          }
        }
      }

    });
  }

  //get drivers salary
  getDriversSalary(){
    // this.authService.getDriversSalary().get().then((item: any) => {
    //   this.driversSalary = item.docs.map(doc => {
    //     const id = doc.id;
    //     doc = doc.data();
    //     doc.id = id;
    //     return doc;
    //   });

    this.authService.getSchedules().get().then((item: any) => {
      this.schedules = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });

      // let firstMonth = true;
      for(let firstMonth = true,  i = 0; i < this.schedules.length; i++,  firstMonth = true){
        //calculate all salaries if there is 'endWork'
        if (this.schedules[i].endWork) {
          //get difference of months
          let differenceOfMonths = Math.abs(this.monthDiff(
            new Date(Number(this.schedules[i].startWork.substr(0,4)), Number(this.schedules[i].startWork.substr(5,2)), Number(this.schedules[i].startWork.substr(8,2))),
            new Date(Number(this.schedules[i].endWork.substr(0,4)), Number(this.schedules[i].endWork.substr(5,2)), Number(this.schedules[i].endWork.substr(8,2)))
          ));
          // console.log(differenceOfMonths+1);

          for(let j = 0; j <= differenceOfMonths; j++){
            // debugger
            //if there is only one month
            if(differenceOfMonths === 0){
              // debugger
              let lastDayOfTheCurrentMonth = new Date(Number(this.schedules[i].endWork.substr(0, 4)), Number(this.schedules[i].endWork.substr(5, 2)), 0).toLocaleDateString("en-US");
              let summm = ((new Date(Number(this.schedules[i].endWork.substr(0,4)), Number(this.schedules[i].endWork.substr(5,2)), Number(this.schedules[i].endWork.substr(8,2))).getTime() - new Date(Number(this.schedules[i].startWork.substr(0,4)), Number(this.schedules[i].startWork.substr(5,2)), Number(this.schedules[i].startWork.substr(8,2))).getTime())/(1000*3600*24)+1)*this.driverSalaryPerDay;
              let salary = {
                "driver": this.schedules[i].driver,
                "date": lastDayOfTheCurrentMonth,
                "summ": summm
              };
              // console.log(salary);
              // console.log('//end//');
              this.testArray.push(salary);
              //here
              //in the end
              if((i+1) == this.schedules.length){
                // console.log('loading');
                // console.log(this.testArray);
                // this.accountFilter = [...this.testArray];
                this.addSameMonths();
              }
              //if there is more than 1 month
            } else if (differenceOfMonths > 0) {
              // debugger
              //calculate first month
              if(firstMonth){
                // debugger
                let lastDayOfTheCurrentMonth = new Date(Number(this.schedules[i].startWork.substr(0, 4)), Number(this.schedules[i].startWork.substr(5, 2)), 0).toLocaleDateString("en-US");
                let summm = (Math.ceil(((new Date(Number(this.schedules[i].startWork.substr(0, 4)), Number(this.schedules[i].startWork.substr(5, 2)), 0).getTime() - new Date(Number(this.schedules[i].startWork.substr(0,4)), Number(this.schedules[i].startWork.substr(5,2)) - 1, Number(this.schedules[i].startWork.substr(8,2))).getTime())/(1000*3600*24)))+1) * this.driverSalaryPerDay;
                let salary = {
                  "driver": this.schedules[i].driver,
                  "date": lastDayOfTheCurrentMonth,
                  "summ": summm
                };
                // console.log(salary);
                this.testArray.push(salary);
                firstMonth = false;
              } else {
                //if the last one month
                if(((Number(this.schedules[i].startWork.substr(5, 2)) + j) === (Number(this.schedules[i].endWork.substr(5, 2)))) || (((Number(this.schedules[i].startWork.substr(5, 2)) + j) == 13) && ((this.schedules[i].endWork.substr(5, 2)) == '01'))){
                  // debugger
                  let lastDayOfTheCurrentMonth = new Date(Number(this.schedules[i].endWork.substr(0, 4)), Number(this.schedules[i].endWork.substr(5, 2)), 0).toLocaleDateString("en-US");
                  let summm = Number(this.schedules[i].endWork.substr(8, 2)) * this.driverSalaryPerDay;
                  let salary = {
                    "driver": this.schedules[i].driver,
                    "date": lastDayOfTheCurrentMonth,
                    "summ": summm
                  };
                  // console.log(salary);
                  // console.log('//end//');

                  this.testArray.push(salary);
                  //here
                  if((i+1) == this.schedules.length){
                    // console.log('loading');
                    // console.log(this.testArray);
                    // this.accountFilter = [...this.testArray];
                    this.addSameMonths();
                  }
                } else {
                  // debugger
                  let lastDayOfTheCurrentMonth = new Date(Number(this.schedules[i].startWork.substr(0, 4)), Number(this.schedules[i].startWork.substr(5, 2)) + j, 0).toLocaleDateString("en-US");
                  let summm = Math.floor(((new Date(Number(this.schedules[i].startWork.substr(0, 4)), Number(this.schedules[i].startWork.substr(5, 2)) + j, 0).getTime()) - (new Date(Number(this.schedules[i].startWork.substr(0, 4)), Number(this.schedules[i].startWork.substr(5, 2)) + j - 1, 1).getTime()))/(1000*3600*24)+1) * this.driverSalaryPerDay;
                  let salary = {
                    "driver": this.schedules[i].driver,
                    "date": lastDayOfTheCurrentMonth,
                    "summ": summm
                  };
                  // console.log(salary);
                  this.testArray.push(salary);
                }
              }
            }
          }
        }
      }
    });

    // console.log(this.testArray);
    // console.log(this.driversSalary);
    // driversSalary
        //set zero to driversSalary of the each month
        // for (let i = 0; i < this.months.length; i++) {
        //   this.months[i].driversSalary = 0;
        // }

        //set new driversSalary
        // for (let i = 0; i < this.salaries.length; i++) {
        //   for (let j = 0; j < this.months.length; j++) {
        //     if ((this.salaries[i].date.substr(0, 2) === this.months[j].number) && (this.salaries[i].date.substr(6, 4) === String(this.currentYear))) {
        //       this.months[j].driversSalary += this.salaries[i].summ;
        //     }
        //   }
        // }

    // console.log(this.testArray);
    // console.log(this.driversSalary);
    // });

  }

  checkDriversSalary(){

    //set zero to driversSalary of the each month
    for (let i = 0; i < this.months.length; i++) {
      this.months[i].driversSalary = 0;
    }
    // debugger
    // console.log('///');
    // console.log(this.driversSalary);
    // console.log('///');
    //set new driversSalary
    for (let i = 0; i < this.driversSalary.length; i++) {
      for (let j = 0; j < this.months.length; j++) {
        // if(((this.testArray[i].date.substr(0, 2) === this.months[j].number) || (this.testArray[i].date.substr(0, 2) === this.months[j].numberTwo))){
        //   debugger
        // }
        // console.log(this.driversSalary[i].date.substr(0, 2));
        // console.log(typeof this.driversSalary[i].date.substr(0, 2));
        // console.log(this.months[j].numberTwo);
        // console.log(typeof this.months[j].numberTwo);
        // if(this.driversSalary[i].date.substr(0, 2) === this.months[j].numberTwo){
        //   // debugger
        // }
        if ((this.driversSalary[i].date.substr(0, 2) === this.months[j].number) && (this.driversSalary[i].date.substr(6, 4) === String(this.currentYear))) {
          this.months[j].driversSalary += this.driversSalary[i].summ;
        }
        // if ((this.driversSalary[i].date.substr(0, 2) === this.months[j].numberTwo) && (this.driversSalary[i].date.substr(5, 4) === String(this.currentYear))) {
        //   this.months[j].driversSalary += this.driversSalary[i].summ;
        // }
      }
    }
    // this.changeFilter();
  }

  //add same months
  addSameMonths(){
    // debugger
    let ob = {};
    // debugger
    for(let i = 0; i < this.testArray.length; i++){
      if(this.testArray[i].date.substr(1,1) === '/'){
        // console.log('i catch / in ' + this.testArray[i].date + '-' + this.testArray[i].driver);
        this.testArray[i].date = '0' + this.testArray[i].date;
      }
    }
    this.driversSalary = this.testArray.reduce(function (r, e) {
      let key = e.driver + '|' + e.date;
      if(!ob[key]) {
        ob[key] = e;
        r.push(ob[key]);
      } else {
        ob[key].summ += e.summ;
      }
      return r;
    }, []);

    this.checkDriversSalary();
    // this.accountFilter = [...this.salaries];
    // this.changeFilter();
  }

  //get logists salary
  getLogistsSalary(){
    this.authService.getLogistsSalary().get().then((item: any) => {
      this.logistsSalary = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });

      for (let i = 0; i < this.months.length; i++) {
        this.months[i].logistsSalary = 0;
      }

      for (let i = 0; i < this.logistsSalary.length; i++) {
        for (let j = 0; j < this.months.length; j++) {
          if ((this.logistsSalary[i].date.substr(5, 2) === this.months[j].number) && (this.logistsSalary[i].date.substr(0, 4) === String(this.currentYear))) {
            this.months[j].logistsSalary += this.logistsSalary[i].summ;
          }
        }
      }
      // this.accountFilter = [...this.logistsSalary];
      // this.changeFilter();
    });
  }

  //get accountant salary
  getAccountantSalary(){
    this.authService.getAccountantSalary().get().then((item: any) => {
      this.accountantsSalary = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });

      for (let i = 0; i < this.months.length; i++) {
        this.months[i].accountantsSalary = 0;
      }

      for (let i = 0; i < this.accountantsSalary.length; i++) {
        for (let j = 0; j < this.months.length; j++) {
          if ((this.accountantsSalary[i].date.substr(5, 2) === this.months[j].number) && (this.accountantsSalary[i].date.substr(0, 4) === String(this.currentYear))) {
            this.months[j].accountantsSalary += this.accountantsSalary[i].summ;
          }
        }
      }
      // this.accountFilter = [...this.accountantsSalary];
      // this.changeFilter();
    });
  }

  //get current years
  getYears () {
    let someYear = this.currentYear;
    for (let i = 0; i <= 5; i++) {
      this.currentYears.push(someYear);
      --someYear;
    }
  }

  //get current months
  getMonths () {
    for (let i = 1; i <= 12; i++) {
      if(i < 10) {
        this.currentMonths.push(String(0) + String(i));
      } else {
        this.currentMonths.push(String(i));
      }

    }
  }

  //difference between two months
  monthDiff(d1, d2) {
    // let months;
    // months = (d2.getFullYear() - d1.getFullYear()) * 12;
    // months -= d1.getMonth() + 1;
    // months += d2.getMonth();
    // return months <= 0 ? 0 : months;
    return d1.getMonth() - d2.getMonth() +
      (12 * (d1.getFullYear() - d2.getFullYear()));
  }

  //search method
  changeFilter(search?) {
    // alert(this.monthFilter);
    this.currentYear = this.yearFilter;
    this.getFuelAccounts();
    this.getYearAccounts();
    this.getAdditionalAccounts();
    this.getTaxes();
    this.checkDriversSalary();
    // this.getDriversSalary();
    // this.checkDriversSalary();
    //////
    this.getLogistsSalary();
    this.getAccountantSalary();
    this.getOrders();

    this.fakeMonths = this.months.filter(item => {
      if(item.number == this.monthFilter){
        return true;
      } else if(this.monthFilter == 'all') {
        return true;
      } else {
        return false;
      }
    });
    // if () {
    //
    // }
    // let toDate = new Date(this.toDate).getTime();
    // let fromDate = new Date(this.fromDate).getTime();
    // debugger
    // this.months = this.accountFilter.filter(item => {
      // let fromDateItem = new Date(item.fromDate).getTime();
      // let searchFiled = item.NIP + item.price + item.distToFreight + item.distWithFreight + item.fromDate + item.toDate + item.fromPlace + item.toPlace;
      // if(toDate && fromDate) {
      //   if (fromDateItem < fromDate || fromDateItem > toDate) {
      //     return false;
      //   }
      // }

      // if (this.yearFilter != 'all') {
      //   // debugger
      //   if (this.currentYear != this.yearFilter) {
      //     return false;
      //   }
      // }
    //   if (this.monthFilter != 'all') {
    //     // debugger
    //     if (item.number != this.monthFilter) {
    //       return false;
    //     }
    //   }
    //
    //   if (search) {
    //     let searchFiled = item.name + item.number;
    //     if (searchFiled.toLowerCase().indexOf(search.toLowerCase()) == -1){
    //       return false;
    //     }
    //   }
    //   return true;
    //
    // });
    // this.countSallary();
  }

  showFilters() {
    if (this.filterSwitcher) {
      this.filterSwitcher = false;
    } else {
      this.filterSwitcher = true;
    }
  }

}
