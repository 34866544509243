import {Injectable, NgZone} from '@angular/core';
import {User} from '../services/user';
import {auth} from 'firebase/app';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data
  admin = new BehaviorSubject(false);
  crntUser = {
    email: ''
  };

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */

    // console.log(crntUser);
    this.afAuth.authState.subscribe(user => {

      if (user) {
        // if (user.email == 'grosmantrans@o2.pl') {
        //   this.crntUser = JSON.parse(localStorage.getItem('user'));
        // }

        if ((this.crntUser.email == 'grosmantrans@o2.pl') && (user.email != 'grosmantrans@o2.pl')) {

          this.admin.next(true);

          // user = this.crntUser;
          localStorage.setItem('user', JSON.stringify(this.crntUser));
          JSON.parse(localStorage.getItem('user'));
        } else {
          this.userData = user;
          if (user.email == 'grosmantrans@o2.pl') {
            this.admin.next(true);
          } else {
            this.admin.next(false);
          }
          localStorage.setItem('user', JSON.stringify(this.userData));
          JSON.parse(localStorage.getItem('user'));
          ///
          if (user.email == 'grosmantrans@o2.pl') {
            this.crntUser = JSON.parse(localStorage.getItem('user'));
            // user = this.crntUser;
          }
          ///
        }
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
        this.crntUser = {
          email: ''
        };
      }

      // console.log(this.crntUser);
      // console.log(user);
    });
  }

  checkAdmin() {
    return this.admin.asObservable();
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return !!user;
  }

  // Sign in with email/password
  SignIn(email, password) {
    this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.router.navigate(['dashboard']);
      }).catch((error) => {
      window.alert(error.message);
    });
  }

  // Sign out
  SignOut() {
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
      this.admin.next(false);
    });
  }

  //cars
  getCars() {
    return this.afs.firestore.collection(`cars`);
  }

  addCar(car) {
    return this.afs.firestore.collection(`cars`).add(car);
  }

  updateCar(car) {
    return this.afs.firestore.collection(`cars`).doc('/' + car.id).set(car);
  }

  removeCar(car) {
    return this.afs.firestore.collection(`cars`).doc('/' + car.id).delete();
  }

  //drivers
  getDrivers() {
    return this.afs.firestore.collection(`drivers`);
  }

  addDriver(driver) {
    return this.afs.firestore.collection(`drivers`).add(driver);
  }

  updateDriver(driver) {
    return this.afs.firestore.collection(`drivers`).doc('/' + driver.id).set(driver);
  }

  removeDriver(driver) {
    return this.afs.firestore.collection(`drivers`).doc('/' + driver.id).delete();
  }

  //logists
  getLogists() {
    return this.afs.firestore.collection(`logists`);
  }

  addLogist(logist) {
    return this.afs.firestore.collection(`logists`).add(logist);
  }

  updateLogist(logist) {
    return this.afs.firestore.collection(`logists`).doc('/' + logist.id).set(logist);
  }

  removeLogist(logist) {
    return this.afs.firestore.collection(`logists`).doc('/' + logist.id).delete();
  }

  //orders
  getOrders() {
    return this.afs.firestore.collection(`orders`);
  }

  addOrder(order) {
    return this.afs.firestore.collection(`orders`).add(order);
  }

  updateOrder(order) {
    return this.afs.firestore.collection(`orders`).doc('/' + order.id).set(order);
  }

  removeOrder(order) {
    return this.afs.firestore.collection(`orders`).doc('/' + order.id).delete();
  }

  //year accounts
  getYearAccounts() {
    return this.afs.firestore.collection(`year-accounts`);
  }

  addYearAccounts(account) {
    return this.afs.firestore.collection(`year-accounts`).add(account);
  }

  updateYearAccounts(account) {
    return this.afs.firestore.collection(`year-accounts`).doc('/' + account.id).set(account);
  }

  removeYearAccounts(account) {
    return this.afs.firestore.collection(`year-accounts`).doc('/' + account.id).delete();
  }

  //fuel accounts
  getFuelAccounts() {
    return this.afs.firestore.collection(`fuel-accounts`);
  }

  addFuelAccounts(account) {
    return this.afs.firestore.collection(`fuel-accounts`).add(account);
  }

  updateFuelAccounts(account) {
    return this.afs.firestore.collection(`fuel-accounts`).doc('/' + account.id).set(account);
  }

  removeFuelAccounts(account) {
    return this.afs.firestore.collection(`fuel-accounts`).doc('/' + account.id).delete();
  }

  //additional accounts
  getAdditionalAccounts() {
    return this.afs.firestore.collection(`additional-accounts`);
  }

  addAdditionalAccounts(account) {
    return this.afs.firestore.collection(`additional-accounts`).add(account);
  }

  updateAdditionalAccounts(account) {
    return this.afs.firestore.collection(`additional-accounts`).doc('/' + account.id).set(account);
  }

  removeAdditionalAccounts(account) {
    return this.afs.firestore.collection(`additional-accounts`).doc('/' + account.id).delete();
  }

  //driver salary
  getDriversSalary() {
    return this.afs.firestore.collection(`drivers-salary`);
  }

  addDriverSalary(salary) {
    return this.afs.firestore.collection(`drivers-salary`).add(salary);
  }

  updateDriverSalary(salary) {
    return this.afs.firestore.collection(`drivers-salary`).doc('/' + salary.id).set(salary);
  }

  removeDriverSalary(salary) {
    return this.afs.firestore.collection(`drivers-salary`).doc('/' + salary.id).delete();
  }

  //logist salary
  getLogistsSalary() {
    return this.afs.firestore.collection(`logists-salary`);
  }

  addLogistSalary(salary) {
    return this.afs.firestore.collection(`logists-salary`).add(salary);
  }

  updateLogistSalary(salary) {
    return this.afs.firestore.collection(`logists-salary`).doc('/' + salary.id).set(salary);
  }

  removeLogistSalary(salary) {
    return this.afs.firestore.collection(`logists-salary`).doc('/' + salary.id).delete();
  }

  //accountant salary
  getAccountantSalary() {
    return this.afs.firestore.collection(`accountant-salary`);
  }

  addAccountantSalary(salary) {
    return this.afs.firestore.collection(`accountant-salary`).add(salary);
  }

  updateAccountantSalary(salary) {
    return this.afs.firestore.collection(`accountant-salary`).doc('/' + salary.id).set(salary);
  }

  removeAccountantSalary(salary) {
    return this.afs.firestore.collection(`accountant-salary`).doc('/' + salary.id).delete();
  }

  //taxes
  getTaxes() {
    return this.afs.firestore.collection(`taxes`);
  }

  addTaxes(account) {
    return this.afs.firestore.collection(`taxes`).add(account);
  }

  updateTaxes(account) {
    return this.afs.firestore.collection(`taxes`).doc('/' + account.id).set(account);
  }

  removeTaxes(account) {
    return this.afs.firestore.collection(`taxes`).doc('/' + account.id).delete();
  }

  //accountants
  getAccountants() {
    return this.afs.firestore.collection(`accountants`);
  }

  addAccountant(accountant) {
    return this.afs.firestore.collection(`accountants`).add(accountant);
  }

  updateAccountant(accountant) {
    return this.afs.firestore.collection(`accountants`).doc('/' + accountant.id).set(accountant);
  }

  removeAccountant(accountant) {
    return this.afs.firestore.collection(`accountants`).doc('/' + accountant.id).delete();
  }

  //schedules
  getSchedules() {
    return this.afs.firestore.collection(`schedules`);
  }

  addSchedule(schedule) {
    return this.afs.firestore.collection(`schedules`).add(schedule);
  }

  updateSchedule(schedule) {
    return this.afs.firestore.collection(`schedules`).doc('/' + schedule.id).set(schedule);
  }

  removeSchedule(schedule) {
    return this.afs.firestore.collection(`schedules`).doc('/' + schedule.id).delete();
  }
}
