import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
// Reactive Form
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// App routing modules
import {AppRoutingModule} from './shared/routing/app-routing.module';
// App components
import {AppComponent} from './app.component';
import {SignInComponent} from './components/sign-in/sign-in.component';
import {SignUpComponent} from './components/sign-up/sign-up.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {VerifyEmailComponent} from './components/verify-email/verify-email.component';
// Firebase services + enviorment module
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {environment} from '../environments/environment';
// Auth service
import {AuthService} from './shared/services/auth.service';
import { OrdersComponent } from './components/orders/orders.component';
import { CarsComponent } from './components/cars/cars.component';
import { DriversComponent } from './components/drivers/drivers.component';
import { AdminOrdersComponent } from './components/admin-orders/admin-orders.component';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AcountantOrdersComponent} from './components/acauntant-orders/acountant-orders.component';
import {LogistsComponent} from "./components/logists/logists.component";
import { AccountingComponent } from './components/accounting/accounting.component';
import { MonthSpendingComponent } from './components/accounting/month-spending/month-spending.component';
import { YearSpendingComponent } from './components/accounting/year-spending/year-spending.component';
import { FuelSpendingComponent } from './components/accounting/fuel-spending/fuel-spending.component';
import { SalariesComponent } from './components/accounting/salaries/salaries.component';
import { AdditionalSpendingComponent } from './components/accounting/additional-spending/additional-spending.component';
import {ScrollDirective} from "./shared/directives/scroll.directive";
import { DriverSalaryComponent } from './components/accounting/salaries/driver-salary/driver-salary.component';
import { LogistSalaryComponent } from './components/accounting/salaries/logist-salary/logist-salary.component';
import { AccountantSalaryComponent } from './components/accounting/salaries/accountant-salary/accountant-salary.component';
import { TaxesComponent } from './components/accounting/taxes/taxes.component';
import { AccountantsComponent } from './components/accountants/accountants.component';
import { ScheduleComponent } from './components/accounting/schedule/schedule.component';

// import {MatDatepickerModule} from '@angular/material/datepicker';
// import {MatDatepickerModule} from '@angular/material/datepicker';



@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    OrdersComponent,
    CarsComponent,
    DriversComponent,
    AdminOrdersComponent,
    AcountantOrdersComponent,
    LogistsComponent,
    AccountingComponent,
    MonthSpendingComponent,
    YearSpendingComponent,
    FuelSpendingComponent,
    SalariesComponent,
    ScrollDirective,
    AdditionalSpendingComponent,
    DriverSalaryComponent,
    LogistSalaryComponent,
    AccountantSalaryComponent,
    TaxesComponent,
    AccountantsComponent,
    ScheduleComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    ReactiveFormsModule
  ],
  providers: [AuthService],
  bootstrap: [AppComponent]
})

export class AppModule {
}
