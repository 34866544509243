import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../shared/services/auth.service';
import {AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from "@angular/fire/storage";
import {Observable} from "rxjs";
import * as moment from "moment";

@Component({
  selector: 'app-cars',
  templateUrl: './cars.component.html',
  styleUrls: ['./cars.component.css']
})
export class CarsComponent implements OnInit {
  filterSwitcher = false;
  public cars: any;
  public newCar: any = {};
  public userId: any;
  public newDocument: any = {};
  public logist: any;
  public currentDatee: any;
  public scrollTop: number;

  showModal: boolean = false;
  showConfirm: boolean = false;
  showDocuments: boolean = false;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;

  constructor(private afStorage: AngularFireStorage, public authService: AuthService) { }

  ngOnInit() {
    this.currentDatee = moment().add(30, 'days').format('YYYY-MM-DD');

    this.userId = this.authService.getCurrentUser().uid;
    this.logist = this.authService.getCurrentUser();
    this.getCars();
  }
///////////car methods
  //get all cars
  getCars() {
    this.authService.getCars().get().then((item: any) => {
      this.cars = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
    });
  }

  //add new car
  addCar(){
    if ( !!this.newCar.countPalet &&
      !!this.newCar.height &&
      !!this.newCar.long &&
      !!this.newCar.model &&
      !!this.newCar.weight &&
      !!this.newCar.width ) {
      this.authService.addCar(this.newCar).then(res => { //
        this.getCars(); //
        this.newCar = {};
        this.showModal = false;
      });
    }
  }

  //edit car
  editCar(car) {
    this.getCars();
    this.newCar = Object.assign({}, car);
    this.showModal = true;
  }

  //update car
  updateCar(car) {
    this.authService.updateCar(car).then(res => {
      this.getCars();
      this.newCar = {};
      this.newDocument = {};
      this.showModal = false;
    })
  }

  //remove car
  removeCar(car) {
    this.authService.removeCar(car).then(res => {
      this.newCar = {};
      this.getCars();
      this.showConfirm = false;
    })
  }


////////document methods
  //remove document
  removeDocument(car, ref){
    car.documents = car.documents.filter(el => el.ref != ref);
    this.updateDocument(car);
  }

  //update document
  updateDocument(car) {
    this.authService.updateCar(car).then(res => {
      this.getCars();
      this.newDocument = {};
    })
  }

  //upload document
  upload(event, car) {
    const id = Math.random().toString(36).substring(2);
    this.ref = this.afStorage.ref(id);
    this.task = this.ref.put(event.target.files[0]);
    this.uploadProgress = this.task.percentageChanges();
    this.task.then(res => {
      this.ref.getDownloadURL().subscribe(res => {
        this.newDocument.ref = res;
      });
    });
  }

  //add document
  addDocument(car){
    if(!car.documents) car.documents = [];
    if(this.userId === 'kb8TZYNLqGQLQuGySOHR5Cahvsw2'){
      car.status = 'sent'
    }
    if (this.newDocument.name && this.newDocument.ref) {
      car.documents.push(this.newDocument);
      this.updateDocument(car);
    }
  }

  //scroll table
  track(event){
    this.scrollTop  = event;
  }

  showFilters() {
    if (this.filterSwitcher) {
      this.filterSwitcher = false;
    } else {
      this.filterSwitcher = true;
    }
  }

}
