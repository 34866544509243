import {Component, OnInit} from '@angular/core';
import {AuthService} from './shared/services/auth.service';
import {NavigationEnd, NavigationStart, Route, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  public accountants: any = [];

  checkForMobileMenu = false;
  admin = false;
  auth = false;
  accountant = false;
  checkForChapter;

  ngOnInit(): void {
    this.authService.checkAdmin().subscribe(admin => {
      this.admin = admin;
    });
    // get all accountants
    this.getAccountants();
    this.checkChapter();
    // for (let i = 0; i < this.accountants.length; i++ ) {
    //   if (this.authService.getCurrentUser().uid === this.accountants[i].uid) {
    //     this.accountant = true;
    //     break;
    //   }
    // }

  }

  constructor(public authService: AuthService, private route: Router) {
    this.route.events.subscribe(event => {
      if(event instanceof NavigationStart){}
      if(event instanceof NavigationEnd){
        // debugger

        // if (this.authService.getCurrentUser().email == 'grosmantrans@o2.pl') {
        //   this.admin = true;
        // }

        // this.auth = this.authService.isLoggedIn;
       this.s();
      }
    });
  }

  s(){
    if (this.authService.getCurrentUser() !== null) {
      // console.log(this.route.url);
      // this.auth = this.authService.isLoggedIn;
      // this.accountant = this.authService.getCurrentUser().uid === 'kb8TZYNLqGQLQuGySOHR5Cahvsw2'; // hardcode

      // debugger
      for (let i = 0; i < this.accountants.length; i++ ) {
        if (this.authService.getCurrentUser().uid === this.accountants[i].uid) {
          this.accountant = true;
          this.auth = this.authService.isLoggedIn;
          break;
        }
        else {
          this.accountant = false;
          this.auth = this.authService.isLoggedIn;
        }
      }

      // console.log(this.route.url);

      this.checkChapter();
      // console.log(this.checkForChapter);
    } else {
      this.admin = false;
      this.auth = false;
      this.accountant = false;
      this.checkForChapter = '';
    }
  }

  //get all accountants
  getAccountants(){
    this.authService.getAccountants().get().then((item: any) => {
      this.accountants = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
      this.s();
    });
  }

  checkChapter(){
    this.checkForChapter = this.route.url === '/dashboard' ? 'dashboard' :
      this.route.url === '/orders' ? 'orders' :
        this.route.url === '/cars' ? 'cars':
          this.route.url === '/drivers' ? 'drivers':
            this.route.url === '/admin-orders' ? 'admin-orders':
              this.route.url === '/logists' ? 'logists' :
                this.route.url === '/accountants' ? 'accountants' :
                  this.route.url === '/accountant' ? 'accountant' :
                    (this.route.url === '/accounting') || (this.route.url === '/accounting/month-spending') || (this.route.url === '/accounting/year-spending') || (this.route.url === '/accounting/fuel-spending') || (this.route.url === '/accounting/salaries') || (this.route.url === '/accounting/additional-spending') || (this.route.url === '/accounting/salaries/driver-salary') || (this.route.url === '/accounting/salaries/logist-salary') || (this.route.url === '/accounting/salaries/accountant-salary') || (this.route.url === '/accounting/taxes') || (this.route.url === '/accounting/schedule') ? 'accounting'  : '';
  }

  showMobileMenu(){
    if (this.checkForMobileMenu){
      this.checkForMobileMenu = false;
    } else {
      this.checkForMobileMenu = true;
    }
  }

  }

  // ngOnInit() {
  //   this.route.events.subscribe(() => {
  //     this.auth = this.authService.isLoggedIn;
  //     this.accountant = this.authService.getCurrentUser().uid === 'kb8TZYNLqGQLQuGySOHR5Cahvsw2';
  //   });
  //   this.authService.checkAdmin().subscribe(admin => {
  //     this.admin = admin;
  //   });
  // }
  // ngOnChanges(changes:SimpleChanges) {
  //   console.log(changes);
  // }

