import { Directive, HostListener, EventEmitter, Output } from "@angular/core";

@Directive({
    selector: "[scroll-directive]"
})
export class ScrollDirective {

    // <summary>
    // Event ouptut the current scroll percentage
    // </summary>
    @Output() onScroll = new EventEmitter<number>();

    // <summary>
    // Holds the current percent value
    // </summary>
    percentValue: number = 0;

    // <summary>
    // Event listener for scroll event on the specific ui element
    // </summary>
    @HostListener("scroll", ["$event"])
    onListenerTriggered(event: UIEvent): void {

        // Calculate the scroll percentage
        const percent = Math.round((event.srcElement.scrollTop / (event.srcElement.scrollHeight - event.srcElement.clientHeight)) * 100);

          // Emit the event
          this.onScroll.emit(event.srcElement.scrollTop);
    }
}
