import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../shared/services/auth.service";

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {
  public cars: any = [];
  public drivers: any = [];
  public newSchedule: any = {};
  public driverFilter: any = 'all';
  public carFilter: any = 'all';
  public filter: string;
  public schedules: any = [];
  public accountFilter: any;
  public scrollTop: number;
  public currentDatee: any = new Date().getTime();

  filterSwitcher = false;
  showModal: boolean = false;
  showConfirm: boolean = false;


  constructor(public authService: AuthService) { }

  ngOnInit() {

    this.getCars();
    this.getDrivers();
    this.getSchedules();
  }

  //get schedules
  getSchedules(){
    this.authService.getSchedules().get().then((item: any) => {
      this.schedules = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });

      for(let i = 0; i < this.schedules.length; i++) {
        let endWorkDate = new Date(this.schedules[i].endWork).getTime();
        // console.log(endWorkDate);
        // console.log(this.currentDatee);
        if (endWorkDate < this.currentDatee) {
          this.schedules[i].status = 'завершив';
        } else {
          this.schedules[i].status = 'працює';
        }
      }

      this.accountFilter = [...this.schedules];
      this.changeFilter();
    });
  }

  //get cars
  getCars() {
    this.authService.getCars().get().then((item: any) => {
      this.cars = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
    });
  }

  //get drivers
  getDrivers() {
    this.authService.getDrivers().get().then((item: any) => {
      this.drivers = item.docs.map(doc => {
        const id = doc.id;
        doc = doc.data();
        doc.id = id;
        return doc;
      });
    });
  }

  //add new schedule
  addSchedule(){
    if ( !!this.newSchedule.driver &&
      !!this.newSchedule.car &&
      !!this.newSchedule.startWork) {
      this.newSchedule.status = 'progress';
        this.authService.addSchedule(this.newSchedule).then(res => {
          this.getSchedules();
          this.newSchedule = {};
          this.showModal = false;
        });
      }

  }

  //edit schedule
  editSchedule(schedule) {
    this.getSchedules();
    this.newSchedule = Object.assign({}, schedule);
    this.showModal = true;
  }

  //update schedule
  updateSchedule(schedule) {
      this.authService.updateSchedule(schedule).then(res => {
        this.getSchedules();
        this.newSchedule = {};
        this.showModal = false;
      });
  }

  //remove schedule
  removeSchedule(schedule) {
    this.authService.removeSchedule(schedule).then(res => {
      this.newSchedule = {};
      this.getSchedules();
      this.showConfirm = false;
    })
  }

  //search method
  changeFilter(search?) {

    this.schedules = this.accountFilter.filter(item => {

      if (this.driverFilter != 'all') {
        if (item.driver  !== this.driverFilter) {
          return false;
        }
      }
      if (this.carFilter != 'all') {
        if (item.car  !== this.carFilter) {
          return false;
        }
      }

      // if (search) {
      //   let searchFiled = item.date + item.driver + item.car + item.summ;
      //   if (searchFiled.toLowerCase().indexOf(search.toLowerCase()) == -1){
      //     return false;
      //   }
      // }
      return true;

    });
  }

  //scroll table
  track(event){
    this.scrollTop  = event;
  }

  showFilters() {
    if (this.filterSwitcher) {
      this.filterSwitcher = false;
    } else {
      this.filterSwitcher = true;
    }
  }

}
